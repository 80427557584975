import {
  AdditionServicesOptionsState,
  makeOptionsForFields,
} from '@pages/AdditionalServices/containers/additionServicesOptionsContext';
import { calculateFieldValues } from '@pages/AdditionalServices/containers/methods/calculateFieldValues';
import { clone } from '@pages/AdditionalServices/containers/methods/clone';
import { AdditionServicesState } from '@pages/AdditionalServices/containers/types';
import { BehaviorSubject } from 'rxjs';

/**
 * makeDirectoryFieldsSubscriber реализует подписчик на изменения данных
 * в стейте. При изменении списка выбранных пользователем директорий
 * вычисляет все поля, которые необходимо показать пользователю.
 * Проверяет, что список полей изменился, если да - обновляет их.
 * Генерирует первоначальное состояние стейта значений полей на основе
 * значений по умолчанию, настроенных в поле.
 *
 * @param serviceContext$
 */
export const makeDirectoryFieldsSubscriber =
  (serviceContext$: BehaviorSubject<AdditionServicesState>) =>
  (servicesStates: AdditionServicesState[], optionsStates?: AdditionServicesOptionsState[]) => {
    if (servicesStates.length === 0) {
      return;
    }

    const serviceState = servicesStates[servicesStates.length - 1];
    const optionsState =
      !optionsStates || optionsStates.length === 0 ? null : optionsStates[optionsStates.length - 1];
    if (!serviceState.IsLastLevelSelected) {
      return;
    }

    const fieldsList = Object.values(serviceState.ServicesPath)
      .map((s) => s.fields)
      .flat(1)
      .sort((a, b) => (a.id < b.id ? 1 : -1));

    /**
     * Вычисляем все идентификаторы полей, используемых для вывода.
     * Если они такие же, как в текущем стейте, то обновлять нет смысла.
     */
    const currentFieldsList = serviceState.FieldsToDisplay.sort((a, b) => (a.id < b.id ? 1 : -1))
      .map((f) => f.id)
      .join('|');

    const newFieldsListID = fieldsList.map((f) => f.id).join('|');

    if (newFieldsListID === currentFieldsList) {
      return;
    }

    const fieldValues: { [T in string]: number } = {};
    fieldsList.map((f) => {
      fieldValues[f.code] = serviceState.FieldValues[f.code] ?? f.value ?? 0;
    });

    // Рассчитывать значения вариантов тут нет смысла, т.к.
    // они еще не загружены.
    serviceContext$.next({
      ...clone(serviceState),
      FieldsToDisplay: fieldsList,
      FieldValues: calculateFieldValues(
        fieldsList,
        fieldValues,
        !!optionsState ? optionsState.FieldOptions : {}
      ),
    });

    makeOptionsForFields(fieldsList);
  };
