import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomsPaymentsDetailingHeader() {
  const { t } = useTranslation(['CustomsPayments']);

  return (
    <>
      <p className="customs-payments-description">
        {t('CustomsPayments:CalculationDescription.Part1')}
        <b>{t('CustomsPayments:CalculationDescription.PardBold')}</b>
        {t('CustomsPayments:CalculationDescription.Part2')}
      </p>
    </>
  );
}

export { CustomsPaymentsDetailingHeader };
