import AdditionServiceFields from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields';
import getCostConfiguration from '@pages/AdditionalServices/components/Service/getCostConfiguration';
import { ServiceProps } from '@pages/AdditionalServices/components/Service/types';
import React, { FC } from 'react';

/**
 * CostColumnFields выводит поля колонки стоимость по настройкам.
 * Выводит редактируемые поля. Является частью и продолжением CostColumn.
 * @param props
 */
const CostColumnFields: FC<ServiceProps> = (props) => {
  const {
    langID,
    service,
    servicesFields,
    globalFields,
    globalFieldValues,
    servicesFieldValues,
    isOptionsLoading,
    options,
    isOrderDisplay,
    onChangeServiceFieldValue,
    onChangeGlobalFieldValue,
  } = props;
  const settings = getCostConfiguration(service);
  if (!settings) {
    return <div className="a-service-template-costConfiguration center">-</div>;
  }

  const serviceFieldCodes = [settings.PriceFieldCode, settings.CurrencyFieldCode];
  const fieldsInService = serviceFieldCodes.filter((c) => servicesFields.find((f) => f.code === c));
  const fieldsToDisplay = [
    ...servicesFields.filter((f) => serviceFieldCodes.includes(f.code)),
    ...globalFields.filter((f) => serviceFieldCodes.includes(f.code)),
  ];

  return (
    <div className="a-service-template-costConfiguration fields">
      <AdditionServiceFields
        fields={fieldsToDisplay}
        fieldValues={{ ...servicesFieldValues, ...globalFieldValues }}
        langID={langID}
        onChange={(field, value) => {
          if (fieldsInService.includes(field)) {
            return onChangeServiceFieldValue(field, value);
          }

          return onChangeGlobalFieldValue(field, value);
        }}
        isOptionsLoading={isOptionsLoading}
        options={options}
        templateSettings={{}}
        isFieldsDisabled={isOrderDisplay}
        allFields={fieldsToDisplay}
      />
    </div>
  );
};

export default CostColumnFields;
