import { Typography } from '@material-ui/core';
import { CustomsPaymentsTemplateStore } from '@pages/AdditionalServices/components/Service/CustomsPaymentsTemplate/types';
import getContractorFromDirectory from '@pages/AdditionalServices/components/ServiceDescription/getContractorFromDirectory';
import getDirectoryFieldsFromData from '@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData';
import { getServiceFieldsByRows } from '@pages/AdditionalServices/containers/methods/basket/getServiceFieldsByRows';
import getServicePathFromData from '@pages/AdditionalServices/containers/methods/basket/getServicePathFromData';
import restoreServiceData from '@pages/AdditionalServices/containers/methods/basket/restoreServiceData';
import ServiceFieldsList from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/ServiceFieldsList';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ServiceGroupBasketItem: FC<ProductSummaryProps> = (props) => {
  const { product, langId } = props;

  const { t } = useTranslation(['AdditionalService', 'CustomsPayments']);

  const restoreResult = restoreServiceData(product);
  if (!restoreResult) {
    return null;
  }

  const { setUpService, otherServices } = restoreResult;

  const path = getServicePathFromData(langId, setUpService);
  const contractor = getContractorFromDirectory(
    langId,
    setUpService.Variant,
    setUpService.ServiceDataCache
  );
  const directoryFields = getDirectoryFieldsFromData(setUpService);

  const serviceFieldsToDisplay = Object.values(
    getServiceFieldsByRows(setUpService, otherServices)
  ).flat();

  if (setUpService.ServiceData.template === 'customsPayments') {
    const { FieldValues, GlobalFieldOptions, TemporaryStore } = setUpService;

    const store: CustomsPaymentsTemplateStore = TemporaryStore[setUpService.service.service_id];
    const settings = setUpService.ServiceData.settings.TemplateSettings.CustomsPayments;

    const customsMode = GlobalFieldOptions[settings.CustomsModeField].find(
      (option) => option.value === FieldValues[settings.CustomsModeField]
    );
    const contractCurrency = GlobalFieldOptions[settings.ContractCurrencyField].find(
      (option) => option.value === FieldValues[settings.ContractCurrencyField]
    );
    const borderPaymentCurrency = GlobalFieldOptions[settings.BorderDeliveryCurrencyField].find(
      (option) => option.value === FieldValues[settings.BorderDeliveryCurrencyField]
    );
    const borderExpensesCurrency = GlobalFieldOptions[settings.OtherExpensesCurrencyField].find(
      (option) => option.value === FieldValues[settings.OtherExpensesCurrencyField]
    );

    return (
      <div className="customs-payments">
        <Typography variant={'h4'}>
          {t('CustomsPayments:CustomsRegimePlaceholder')}: {customsMode.origin.name}
        </Typography>
        <Typography variant={'h4'}>
          {t('CustomsPayments:BorderShippingCosts')}: {FieldValues[settings.BorderDeliverySumField]}{' '}
          {borderPaymentCurrency.label}
        </Typography>
        <Typography variant={'h4'}>
          {t('CustomsPayments:OtherExpenses')}: {FieldValues[settings.OtherExpensesSumField]}{' '}
          {borderExpensesCurrency.label}
        </Typography>
        {store.products.map((product, index) => (
          <Typography key={'customs-payment-product-basket-' + product.productId} variant={'h4'}>
            {t('CustomsPayments:ProductTitle')} №{index + 1}: {product.code} (
            {product.codeDescription}), {product.contractCurrencyCost} {contractCurrency.label}
          </Typography>
        ))}
      </div>
    );
  }

  return (
    <div className="a-service-basket">
      <div className="caption">{path}</div>
      <div className="properties">
        <div className="property">
          <div className="name">
            <div className="description">
              {t('AdditionalService:ServiceDescriptionContractor')}:
            </div>
            <div>{contractor}</div>
          </div>
        </div>
      </div>
      <ServiceFieldsList
        fields={[...directoryFields, ...serviceFieldsToDisplay]}
        langID={langId}
        setUpService={setUpService}
        otherServices={otherServices}
      />
    </div>
  );
};

export default ServiceGroupBasketItem;
