import { MButton } from '@assets/mui/@material-extend';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import { ServiceField } from '@onlog-public/additional-services-types';
import { AdditionServiceFieldsProps } from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/types';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdditionServiceFieldsRow from './AdditionServiceFieldsRow';

interface AdditionServiceFieldsAccordionProps {
  rows: {
    idx: number;
    fields: ServiceField[];
  }[];
}

const AdditionServiceFieldsAccordion: FC<
  AdditionServiceFieldsProps & AdditionServiceFieldsAccordionProps
> = (props) => {
  const {
    templateSettings,
    options,
    langID,
    fieldValues,
    onChange,
    isOptionsLoading,
    isFieldsDisabled,
    validation = {},
    rows,
    allFields,
  } = props;

  const { t } = useTranslation();

  const settings = rows?.[0]?.fields?.[0]?.settings?.DefaultSettings;

  const [isExpanded, setIsExpanded] = useState<boolean>(!settings.IsAccordionDefaultCollapsed);

  const ExpandIcon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;

  const toggleAccordionExpandHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div className="addition-service-fields-row">
        <div
          className={clsx(
            'addition-service-block',
            `block-size-${settings.AccordionButtonBlockSize}`
          )}
        >
          <div
            className={clsx(
              'addition-service-field',
              `field-size-${settings.AccordionButtonWidth}`
            )}
          >
            <Tooltip placement="top" title={t(settings.AccordionHelperText)}>
              <MButton
                className={'get-rates-btn product-help-expand'}
                variant={'outlined'}
                color={'inherit'}
                fullWidth={true}
                endIcon={<ExpandIcon fontSize="medium" />}
                onClick={toggleAccordionExpandHandler}
              >
                {t(settings.AccordionButtonText)}
              </MButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {isExpanded &&
        rows.map((r) => (
          <AdditionServiceFieldsRow
            key={r.idx}
            templateSettings={templateSettings}
            langID={langID}
            fields={r.fields}
            fieldValues={fieldValues}
            onChange={onChange}
            options={options}
            validation={validation}
            isFieldsDisabled={isFieldsDisabled}
            isOptionsLoading={isOptionsLoading}
            allFields={allFields}
          />
        ))}
    </>
  );
};

export default AdditionServiceFieldsAccordion;
