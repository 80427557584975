import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { ServiceField, ServiceFieldSettings } from '@onlog-public/additional-services-types';
import TextDisplayField from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/TextDisplayField';
import { SelectOption } from '@pages/AdditionalServices/containers/services/optionsGenerator/types';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

interface NumberFieldProps {
  value: number;
  settings: ServiceFieldSettings;
  isFieldsDisabled: boolean;
  tooltip: string;
  placeholder: string;
  allOptions: { [T in string]?: SelectOption[] };
  allFields: ServiceField[];
  fieldValues: { [T in string]: number };

  onChange: (value: number) => void;
}

const NumberField: FC<NumberFieldProps> = (props) => {
  const {
    value,
    settings,
    tooltip,
    placeholder,
    allOptions,
    onChange,
    isFieldsDisabled,
    fieldValues,
  } = props;

  const fieldSize = settings.FieldSize === 'small' ? 'small' : 'medium';
  const [stateValue, setStateValue] = useState(String(value ?? ''));
  const isCurrencyCourseField =
    settings.NumberFieldSettings.IsCurrencyCourseField &&
    settings.NumberFieldSettings.CurrencyField.length !== 0;

  const currencyValue = fieldValues[settings.NumberFieldSettings.CurrencyField];
  const currency = isCurrencyCourseField
    ? allOptions[settings.NumberFieldSettings.CurrencyField]?.find((c) => c.value === currencyValue)
    : null;

  useEffect(() => {
    const val = parseFloat(stateValue);
    if (Number.isNaN(val)) {
      return setStateValue(String(value ?? ''));
    }

    if (val === value) {
      return;
    }

    setStateValue(String(value ?? ''));
  }, [value]);

  // Обработчик изменения значения поля
  const onChangeValue = (value: string) => {
    let regexp = /^-?[0-9]*\.?[0-9]*$/;
    if (!settings.NumberFieldSettings.EnableDecimalValues) {
      regexp = /^-?[0-9]*$/;
    }

    const val = value.replace(',', '.');
    if (!regexp.test(val) && val.length) {
      return;
    }

    setStateValue(val);

    const parsed = parseFloat(val);
    if (Number.isNaN(parsed)) {
      return;
    }

    onChange(parsed);
  };

  // onModify реализует обработчик ручного изменения поля при помощи кнопок +/-
  const onModify = (modification: number) => {
    let val = parseFloat(stateValue);
    if (Number.isNaN(val)) {
      val = value;
    }

    val = val + modification;

    if (
      settings.NumberFieldSettings.LimitMinValue !== undefined &&
      val < settings.NumberFieldSettings.LimitMinValue
    ) {
      val = settings.NumberFieldSettings.LimitMinValue;
      setStateValue(String(settings.NumberFieldSettings.LimitMinValue));
    }

    if (
      settings.NumberFieldSettings.LimitMaxValue !== undefined &&
      val > settings.NumberFieldSettings.LimitMaxValue
    ) {
      val = settings.NumberFieldSettings.LimitMaxValue;
      setStateValue(String(settings.NumberFieldSettings.LimitMaxValue));
    }

    setStateValue(String(val));
    onChange(val);
  };

  // finishChangeField метод выполняет итоговую проверку значения при завершении изменений поля
  const finishChangeField = () => {
    let parsed = parseFloat(stateValue);
    if (Number.isNaN(parsed)) {
      setStateValue(String(settings.NumberFieldSettings.LimitMinValue ?? 0));
      return onChange(settings.NumberFieldSettings.LimitMinValue ?? 0);
    }

    if (
      settings.NumberFieldSettings.LimitMinValue !== undefined &&
      parsed < settings.NumberFieldSettings.LimitMinValue
    ) {
      parsed = settings.NumberFieldSettings.LimitMinValue;
      setStateValue(String(settings.NumberFieldSettings.LimitMinValue));
    }

    if (
      settings.NumberFieldSettings.LimitMaxValue !== undefined &&
      parsed > settings.NumberFieldSettings.LimitMaxValue
    ) {
      parsed = settings.NumberFieldSettings.LimitMaxValue;
      setStateValue(String(settings.NumberFieldSettings.LimitMaxValue));
    }

    setStateValue(String(parsed));
    onChange(parsed);
  };

  const formattedPlaceholder =
    isCurrencyCourseField && !!currency ? `${placeholder} ${currency.label}` : placeholder;

  if (settings.IsNeedDisplayOnlyTextInfo) {
    return <TextDisplayField tooltip={tooltip} value={String(value)} />;
  }

  return (
    <Tooltip
      placement="top"
      title={
        tooltip.length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: tooltip }} className="a-html-content" />
        )
      }
    >
      <TextField
        fullWidth
        size={fieldSize}
        label={formattedPlaceholder}
        className={clsx('a-service-field-number', fieldSize, {
          [`direction-${settings.NumberFieldSettings.FieldTextDirection}`]: true,
        })}
        variant="outlined"
        value={stateValue}
        onChange={(event) => onChangeValue(event.target.value)}
        onBlur={() => finishChangeField()}
        disabled={isFieldsDisabled || settings.IsNeedDisableField}
        InputLabelProps={{
          shrink: true,
          size: 'small',
        }}
        InputProps={{
          endAdornment: settings.NumberFieldSettings.IsNeedDisplayChangeButtons && (
            <IconButton
              sx={{ width: 17, height: 17, backgroundColor: '#EFF3FB' }}
              onClick={() => onModify(1)}
              disabled={isFieldsDisabled || settings.IsNeedDisableField}
            >
              <AddIcon sx={{ fontSize: 11, color: '#707070' }} />
            </IconButton>
          ),
          startAdornment: settings.NumberFieldSettings.IsNeedDisplayChangeButtons && (
            <IconButton
              sx={{ width: 17, height: 17, backgroundColor: '#EFF3FB' }}
              onClick={() => onModify(-1)}
              disabled={isFieldsDisabled || settings.IsNeedDisableField}
            >
              <RemoveIcon sx={{ fontSize: 11, color: '#707070' }} />
            </IconButton>
          ),
        }}
      />
    </Tooltip>
  );
};

export default NumberField;
