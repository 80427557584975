import { MButton } from '@assets/mui/@material-extend';
import palette from '@assets/theme/palette';
import { useMediaQuery } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { Service, ServiceDirectory } from '@onlog-public/additional-services-types';
import { customsTaxService } from '@services/requests/customsTaxService';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStyleFormattedNote } from '../helpers/getStyleFormattedNote';
import { CustomsPaymentsTemplateProduct, CustomsPaymentsTemplateStore } from '../types';
import { ProductHelpItem } from './ProductHelpItem';

interface ProductHelpProps {
  product: CustomsPaymentsTemplateProduct;
  productIndex: number;
  customsMode: number;
  serviceTemplateStore: { [T in string]: any };
  variant: ServiceDirectory;
  service: Service;
  onChangeServiceTemplateStore: (variant: string, service: string, store: any) => void;
}

function ProductHelp({
  product,
  productIndex,
  customsMode,
  serviceTemplateStore,
  service,
  variant,
  onChangeServiceTemplateStore,
}: ProductHelpProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];

  const [expandedProductHelps, setExpandedProductHelps] = useState<number[]>([]);
  const [isProductHelpExpanded, setIsProductHelpExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { code, country, originCertificateExisting, notes } = product;

  useEffect(() => {
    const fetchFeatures = async () => {
      setIsLoading(true);
      setExpandedProductHelps([]);

      const formattedCustomsMode = customsMode === 1 ? 'import' : 'export';
      const formattedCertificate = originCertificateExisting ? '1' : '0';

      const features = await customsTaxService().LoadFeatures({
        mode: formattedCustomsMode,
        tncode: code,
        certificate: formattedCertificate,
        country,
      });

      const newStore = { ...store };
      const productCopy = { ...newStore.products[productIndex] };

      productCopy.notes = (features ?? []).map((feature) => {
        const tempWrapNode = document.createElement('div');
        tempWrapNode.innerHTML = feature.description;

        getStyleFormattedNote(tempWrapNode, 'table.bordered', 'altaTaksaTable', ['bordered']);
        getStyleFormattedNote(tempWrapNode, '.altaTaksaTable tbody', 'altaTaksaTable__tbody', []);
        getStyleFormattedNote(tempWrapNode, '.altaTaksaTable tr', 'altaTaksaTable__row', []);
        getStyleFormattedNote(tempWrapNode, '.altaTaksaTable td', 'altaTaksaTable__td', []);
        getStyleFormattedNote(tempWrapNode, '.black.bold', 'altaTaksaNote__header', [
          'black',
          'bold',
        ]);
        getStyleFormattedNote(tempWrapNode, '.p-10', 'altaTaksaNote__body', ['p-10']);

        return { ...feature, description: tempWrapNode.innerHTML };
      });
      newStore.products.splice(productIndex, 1, productCopy);

      onChangeServiceTemplateStore(variant.id, service.id, newStore);

      setIsLoading(false);
    };

    setIsProductHelpExpanded(false);
    fetchFeatures();
  }, [customsMode, originCertificateExisting, code, country]);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExpandNotesButtonDisabled = !notes.length;

  const expandAllHandler = () => {
    setExpandedProductHelps(notes.map((_, index) => index));
  };

  const collapseAllHandler = () => {
    setExpandedProductHelps([]);
  };

  const toggleExpandItemHandler = (item: number) => {
    setExpandedProductHelps((prevState) => {
      if (!prevState.includes(item)) {
        return [...prevState, item];
      }

      return prevState.filter((i) => item !== i);
    });
  };

  const toggleProductHelpExpandHandler = () => {
    setIsProductHelpExpanded((prevState) => !prevState);
  };

  const ExpandIcon = isProductHelpExpanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <div className="product-help-wrap">
      <MButton
        className={'get-rates-btn product-help-expand'}
        variant={'outlined'}
        color={'secondary'}
        fullWidth={true}
        disabled={isExpandNotesButtonDisabled || isLoading}
        endIcon={
          <ExpandIcon
            fontSize="medium"
            style={{
              color:
                isExpandNotesButtonDisabled || isLoading ? 'rgba(145, 158, 171, 0.8)' : '#3460B4',
            }}
          />
        }
        onClick={toggleProductHelpExpandHandler}
      >
        {isLoading && (
          <CircularProgress
            size={20}
            style={{ color: 'rgba(145, 158, 171, 0.8)', marginRight: '20px' }}
          />
        )}
        {t('CustomsPayments:AdditionalProductInformation')}
      </MButton>
      {isProductHelpExpanded && (
        <>
          <div className="product-help-control">
            <Link
              className="product-help-control-btn"
              color={palette.light.blue[400]}
              target="_blank"
              underline={'none'}
              onClick={expandAllHandler}
            >
              {isMobile && <KeyboardDoubleArrowDownIcon fontSize="small" />}
              {t('CustomsPayments:ExpandAllBtn')}
            </Link>
            <Link
              className="product-help-control-btn"
              color={palette.light.blue[400]}
              target="_blank"
              underline={'none'}
              onClick={collapseAllHandler}
            >
              {isMobile && <KeyboardDoubleArrowUpIcon fontSize="small" />}
              {t('CustomsPayments:CollapseAllBtn')}
            </Link>
          </div>
          <div className="product-help-list">
            {notes.map((note, index) => (
              <ProductHelpItem
                key={'product-help-item-' + index}
                note={note}
                id={index}
                isExpanded={expandedProductHelps.includes(index)}
                onToggleExpandItem={toggleExpandItemHandler}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export { ProductHelp };
