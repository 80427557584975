import { MButton } from '@assets/mui/@material-extend';
import palette from '@assets/theme/palette';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { customsTaxService } from '@services/requests/customsTaxService';
import {
  CountriesResponse,
  ExportCalculationRateResponse,
  ImportCalculationRateResponse,
} from '@services/requests/customsTaxService/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProps } from '../../types';
import { CustomsPaymentsCodeSelect } from '../CustomsPaymentsCodeSelect';
import { checkErrorExisting, validateCustomsPaymentProducts } from '../helpers/helpers';
import { CustomsPaymentsTemplateProduct, CustomsPaymentsTemplateStore } from '../types';
import { AdditionalTaxList } from './AdditionalTax';
import { CountFields } from './CountFields';
import { ProductHelp } from './ProductHelp';
import { ResultTables } from './ResultTables';
import { Container } from './StyledComponents';
import { AdditionalTax, isResponseValidateErrorPredicate } from './types';

interface CustomsPaymentsProductItemProps extends ServiceProps {
  countries: CountriesResponse;
  productIndex: number;
  isLastProduct: boolean;
  totalContractCurrencyCost: number;
  totalProductsWeight: number;
}

function CustomsPaymentsProductItem({
  productIndex,
  isLastProduct,
  countries,
  totalProductsWeight,
  totalContractCurrencyCost,
  ...baseProps
}: CustomsPaymentsProductItemProps) {
  const {
    variant,
    service,
    globalFields,
    servicesFields,
    serviceTemplateStore,
    options,
    globalFieldValues,
    servicesFieldValues,
    isOrderDisplay,
    onChangeServiceTemplateStore,
  } = baseProps;

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];
  const product = store.products[productIndex];

  const { t } = useTranslation(['CustomsPayments']);

  const [additionalTax, setAdditonalTax] = useState<AdditionalTax | null>(null);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const fields = [...servicesFields, ...globalFields];
  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const customsModeField = fields.find((f) => f.code === settings.CustomsModeField);
  const contractCurrencyField = fields.find((f) => f.code === settings.ContractCurrencyField);

  const currencies = options[contractCurrencyField.code].map((option) => option.origin);

  const {
    productId,
    codeDescription,
    contractCurrencyCost,
    country,
    originCertificateExisting,
    weight,
    selectedFee,
    selectedAntidumpingFee,
    selectedExcise,
    selectedExportFee,
    selectedVat,
    isCalculated,
    invalidFields,
    code,
  } = product;

  const customsMode =
    options[customsModeField.code].find(
      (option) => option.value === fieldValues[customsModeField.code]
    )?.origin?.value ?? 1;

  const isExport = customsMode === 2;

  const autocompleteFormattedCountries = (countries || []).map((country) => ({
    value: country.Code,
    label: country.Name[0] + country.Name.slice(1).toLowerCase(),
  }));

  const selectedCountry = autocompleteFormattedCountries.find((c) => c.value === country);

  const countryTypeText =
    customsMode === 1
      ? t('CustomsPayments:OriginCountryPlaceholder')
      : t('CustomsPayments:DestinationCountryPlaceholder');

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';

  const errors = useMemo(() => {
    const errors = invalidFields ?? [];
    const filteredErrors = errors.filter((key) => {
      if (
        !isSpecialFieldsVisible &&
        (key === 'packagesCount' || key === 'maximumRetailPackagePrice')
      ) {
        return false;
      }
      return true;
    });

    return filteredErrors;
  }, [invalidFields, productIndex, isSpecialFieldsVisible]);

  const getRatesBtnTextColor = checkErrorExisting(['isCalculated'], errors)
    ? palette.light.error.main
    : palette.light.secondary.main;

  const changeProductValueHandler = <T extends keyof CustomsPaymentsTemplateProduct>(
    key: T,
    value: CustomsPaymentsTemplateProduct[T]
  ) => {
    const newStore = { ...store };
    const foundProductIndex = newStore.products.findIndex(
      (product) => product.productId === productId
    );

    if (foundProductIndex === -1) {
      return;
    }

    const changedProduct = { ...newStore.products[foundProductIndex] };
    changedProduct[key] = value;
    changedProduct.invalidFields = changedProduct.invalidFields.filter(
      (errorKey) => errorKey !== key
    );

    newStore.products.splice(foundProductIndex, 1, changedProduct);

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });
  };

  const changeOriginCountryHandler = (value: typeof selectedCountry) => {
    const newStore = { ...store };

    const foundProductIndex = newStore.products.findIndex(
      (product) => product.productId === productId
    );

    if (foundProductIndex === -1) {
      return;
    }

    const changedProduct = { ...newStore.products[foundProductIndex] };
    changedProduct.country = value?.value;
    changedProduct.invalidFields = changedProduct.invalidFields.filter(
      (errorKey) => errorKey !== 'country'
    );

    newStore.products.splice(productIndex, 1, changedProduct);

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });
  };

  const removeCustomsPaymentProductHandler = (index: number) => {
    const newStore = { ...store };

    const storeWithRemovedProduct = newStore.products.filter((_, i) => i !== index);

    onChangeServiceTemplateStore(variant.id, service.id, {
      ...newStore,
      products: storeWithRemovedProduct,
    });
  };

  const highlightInvalidFieldsHandler = (filterErrorKeys: string[]) => {
    const newStore = { ...store };

    const productErrors = validateCustomsPaymentProducts(newStore.products, filterErrorKeys);
    const errors = productErrors.map((item) => [...item]);

    const changedProduct = { ...newStore.products[productIndex] };
    changedProduct.invalidFields = errors[productIndex];
    newStore.products.splice(productIndex, 1, changedProduct);

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });

    return errors[productIndex];
  };

  const resetFieldsHandler = () => {
    changeProductValueHandler('productsCount', '0');
    changeProductValueHandler('maximumRetailPackagePrice', '0');
    changeProductValueHandler('packagesCount', '0');
  };

  const resetTaxParamsHandler = () => {
    changeProductValueHandler('selectedFee', null);
    changeProductValueHandler('selectedAntidumpingFee', null);
    changeProductValueHandler('selectedExcise', null);
    changeProductValueHandler('selectedVat', null);
    changeProductValueHandler('selectedExportFee', null);
    setAdditonalTax(null);
    changeProductValueHandler('isCalculated', false);
    changeProductValueHandler('sborList', []);
    changeProductValueHandler('total', {});
  };

  useEffect(() => {
    if (isOrderDisplay) {
      return;
    }

    if (customsMode) {
      resetFieldsHandler();
      resetTaxParamsHandler();
    }
  }, [customsMode]);

  const calculateAdditionalTaxHandler = async () => {
    const errors = highlightInvalidFieldsHandler(['code']);
    const isCodeError = errors.includes('code');

    if (isCalculated || isCodeError) {
      return;
    }

    setIsCalculating(true);
    const formattedCustomsMode = customsMode === 1 ? 'import' : 'export';
    const formattedCertificate = originCertificateExisting ? '1' : '0';

    try {
      const result = await customsTaxService().CalculateRates(formattedCustomsMode, {
        mode: formattedCustomsMode,
        tncode: code,
        certificate: formattedCertificate,
        country,
      });

      const resultSbor = await customsTaxService().LoadSbor({
        mode: formattedCustomsMode,
        tnved: code,
      });

      if (isResponseValidateErrorPredicate(result) || isResponseValidateErrorPredicate(result)) {
        setIsCalculating(false);
        return;
      }

      const fees =
        formattedCustomsMode === 'import'
          ? (result as ImportCalculationRateResponse).Importlist.Import
          : [];
      const formattedFees = Array.isArray(fees) ? fees : [fees];

      const exportFees =
        formattedCustomsMode === 'export'
          ? (result as ExportCalculationRateResponse).Exportlist.Export
          : [];
      const formattedExportFees = Array.isArray(exportFees) ? exportFees : [exportFees];

      const vats =
        formattedCustomsMode === 'import'
          ? (result as ImportCalculationRateResponse).VATlist.VAT
          : [];
      const formattedVats = Array.isArray(vats) ? vats : [vats];
      const formattedUniqueVats = formattedVats.map((vat, index) => ({
        ...vat,
        Value: vat.Value + '|' + index,
      }));

      const excises = result.Exciselist?.Excise;
      let formattedUniqueExices = [];
      if (excises) {
        const formattedExices = Array.isArray(excises) ? excises : [excises];
        formattedUniqueExices = formattedExices.map((vat, index) => ({
          ...vat,
          Value: vat.Value + '|' + index,
        }));
      }

      const defaultSelectedFee = formattedFees?.[0] ?? null;
      const defaultSelectedAntidumpingFee =
        formattedFees?.filter((fee) => fee?.Note === 'Антидемпинговая пошлина')?.[0] ?? null;
      const defaultSelectedExportFee = formattedExportFees?.[0] ?? null;
      const defaultSelectedVat =
        formattedUniqueVats?.find((vat) => vat.Value.includes('20%')) ??
        formattedUniqueVats?.[0] ??
        null;
      const defaultSelectedExcise = formattedUniqueExices?.[0] ?? null;

      setAdditonalTax({
        excises: formattedUniqueExices,
        fees: formattedFees,
        vats: formattedUniqueVats,
        exportFees: formattedExportFees,
      });

      changeProductValueHandler('selectedFee', defaultSelectedFee);
      changeProductValueHandler('selectedAntidumpingFee', defaultSelectedAntidumpingFee);
      changeProductValueHandler('selectedExcise', defaultSelectedExcise);
      changeProductValueHandler('selectedVat', defaultSelectedVat);
      changeProductValueHandler('selectedExportFee', defaultSelectedExportFee);
      changeProductValueHandler('isCalculated', true);
      changeProductValueHandler('sborList', resultSbor);
      setIsCalculating(false);

      highlightInvalidFieldsHandler([
        'productsCount',
        'packagesCount',
        'maximumRetailPackagePrice',
        'weight',
        'contractCurrencyCost',
      ]);
    } catch (e) {
      setIsCalculating(false);
    }
  };

  return (
    <Container>
      <div className="product-header-wrap">
        <div className="title">
          {t('CustomsPayments:ProductTitle')} #{productIndex + 1}
        </div>
        {!isLastProduct && (
          <IconButton
            size={'small'}
            onClick={() => removeCustomsPaymentProductHandler(productIndex)}
          >
            <CloseIcon sx={{ fontSize: 25 }} />
          </IconButton>
        )}
      </div>
      <div className="main-config-wrap">
        <Autocomplete
          fullWidth
          disablePortal
          id="origin-country"
          className="origin-country"
          value={selectedCountry || null}
          onChange={(_, value) => {
            changeOriginCountryHandler(value);
            resetFieldsHandler();
            resetTaxParamsHandler();
          }}
          options={autocompleteFormattedCountries}
          renderInput={(params) => (
            <TextField
              {...params}
              error={checkErrorExisting(['country'], errors)}
              label={countryTypeText}
            />
          )}
        />
        <CustomsPaymentsCodeSelect
          onChangeCode={changeProductValueHandler}
          onResetTaxParams={resetTaxParamsHandler}
          onResetFields={resetFieldsHandler}
          errors={errors}
          codeDescription={codeDescription}
        />
        <TextField
          fullWidth
          size={'medium'}
          type={'number'}
          className="cost-field-input"
          error={checkErrorExisting(['weight'], errors)}
          label={t('CustomsPayments:GrossWeightPlaceholder')}
          variant="outlined"
          value={weight}
          onChange={(event) => {
            changeProductValueHandler('weight', event.target.value);
          }}
        />
        <TextField
          fullWidth
          size={'medium'}
          type={'number'}
          className="cost-field-input"
          error={checkErrorExisting(['contractCurrencyCost'], errors)}
          label={t('CustomsPayments:PricePlaceholder')}
          variant="outlined"
          value={contractCurrencyCost}
          onChange={(event) =>
            changeProductValueHandler('contractCurrencyCost', event.target.value)
          }
        />
      </div>
      <div>
        <FormControlLabel
          className="origin-certificate"
          label={t('CustomsPayments:CertificateOriginAvailable')}
          control={
            <Checkbox
              checked={originCertificateExisting}
              onChange={(event) => {
                changeProductValueHandler('originCertificateExisting', event.target.checked);
                resetFieldsHandler();
                resetTaxParamsHandler();
              }}
              size={'small'}
            />
          }
        />
      </div>
      <div>
        <MButton
          className={'get-rates-btn'}
          variant={'outlined'}
          color={checkErrorExisting(['isCalculated'], errors) ? 'error' : 'secondary'}
          fullWidth={true}
          onClick={calculateAdditionalTaxHandler}
        >
          {isCalculating && (
            <CircularProgress
              size={20}
              style={{ color: palette.light.secondary.main, marginRight: '20px' }}
            />
          )}
          <Typography color={getRatesBtnTextColor} fontSize={14}>
            {t('CustomsPayments:CalculatePaymentsBtn')}
          </Typography>
        </MButton>
      </div>
      {additionalTax && isCalculated && (
        <AdditionalTaxList
          additionalTax={additionalTax}
          selectedExcise={selectedExcise}
          selectedFee={selectedFee}
          selectedVat={selectedVat}
          selectedAntidumpingFee={selectedAntidumpingFee}
          onChangeProductValue={changeProductValueHandler}
        />
      )}

      {isCalculated && (
        <CountFields
          invalidFields={invalidFields}
          selectedExcise={selectedExcise}
          selectedFee={selectedFee}
          productIndex={productIndex}
          variant={variant}
          service={service}
          serviceTemplateStore={serviceTemplateStore}
          onChangeProductValue={changeProductValueHandler}
          onChangeServiceTemplateStore={onChangeServiceTemplateStore}
        />
      )}

      {isCalculated && (
        <ResultTables
          currencies={currencies}
          selectedExcise={selectedExcise}
          selectedFee={selectedFee}
          selectedAntidumpingFee={selectedAntidumpingFee}
          selectedVat={selectedVat}
          selectedExportFee={selectedExportFee}
          isExport={isExport}
          globalFieldValues={globalFieldValues}
          globalFields={globalFields}
          servicesFieldValues={servicesFieldValues}
          servicesFields={servicesFields}
          options={options}
          productIndex={productIndex}
          service={service}
          variant={variant}
          serviceTemplateStore={serviceTemplateStore}
          totalContractCurrencyCost={totalContractCurrencyCost}
          totalProductsWeight={totalProductsWeight}
          onChangeServiceTemplateStore={onChangeServiceTemplateStore}
        />
      )}

      <ProductHelp
        product={product}
        productIndex={productIndex}
        customsMode={customsMode}
        service={service}
        variant={variant}
        serviceTemplateStore={serviceTemplateStore}
        onChangeServiceTemplateStore={onChangeServiceTemplateStore}
      />
    </Container>
  );
}

export { CustomsPaymentsProductItem };
