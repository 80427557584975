import { ExciseItem } from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

import { CustomsPaymentsTemplateProduct } from '../types';
import { getCalculation } from './calculation';

interface UseExciseCalcProps {
  cost: number;
  selectedExcise: ExciseItem;
  product: CustomsPaymentsTemplateProduct;
  title: string;
  currencies: CurrencyData[];
}

function getExciseCalc({ selectedExcise, product, title, cost, currencies }: UseExciseCalcProps) {
  const { productsCount, exciseCount, maximumRetailPackagePrice, packagesCount, selectedFee } =
    product;

  const isExciseCountFieldVisible =
    !!selectedExcise && selectedExcise?.Measure?.Razm !== selectedFee?.Measure?.Razm;

  if (!selectedExcise) {
    return null;
  }

  const count = selectedExcise?.ValueDetail?.ValueCount ?? 0;
  const unit = selectedExcise?.ValueDetail?.ValueUnit ?? '';
  const localCurrency = selectedExcise?.ValueDetail?.ValueCurrency ?? '';
  const feeBase =
    unit.indexOf('т.шт') !== -1 || !isExciseCountFieldVisible ? +productsCount : +exciseCount;
  const foundCurrency = currencies.find((currency) => currency.code === localCurrency);

  let result = getCalculation(
    +count,
    unit,
    localCurrency,
    foundCurrency?.course ?? 1,
    cost,
    feeBase
  );

  if (selectedExcise.ValueDetailAdd) {
    const feeBase3 = +exciseCount;
    const count3 = +selectedExcise.ValueDetailAdd.ValueCount;
    const unit3 = selectedExcise.ValueDetailAdd.ValueUnit;
    const localCurrency3 = selectedExcise.ValueDetailAdd.ValueCurrency;
    if (selectedExcise.Prim) {
      const exciseSpecialPrice = +maximumRetailPackagePrice;
      const exciseSpecialCount =
        typeof selectedExcise.Measure.Razm === 'string' &&
        selectedExcise.Measure.Razm.indexOf('т.шт') !== -1
          ? +packagesCount * 1000
          : +packagesCount;
      const specialCost = exciseSpecialPrice * exciseSpecialCount;

      result += getCalculation(
        count3,
        unit3,
        localCurrency3,
        foundCurrency?.course ?? 1,
        specialCost,
        feeBase3
      );
    } else {
      result += getCalculation(
        count3,
        unit3,
        localCurrency3,
        foundCurrency?.course ?? 1,
        cost,
        feeBase3
      );
    }
  }

  if (selectedExcise?.Value !== '0' && selectedExcise?.ValueDetail?.ValueCount2) {
    const feeBase2 = +productsCount;
    const count2 = +selectedExcise.ValueDetail.ValueCount2;
    const unit2 = selectedExcise.ValueDetail.ValueUnit2;
    const localCurrency2 = selectedExcise.ValueDetail.ValueCurrency2;

    const result2 = getCalculation(
      count2,
      unit2,
      localCurrency2,
      foundCurrency?.course ?? 1,
      cost,
      feeBase2
    );

    return {
      id: 'excise',
      name: title,
      rate: `${selectedExcise.ValueDetail.ValueCount2}${selectedExcise.ValueDetail.ValueCurrency2}/${selectedExcise.ValueDetail.ValueUnit2}`,
      rublesAmount: result < result2 ? result2.toString() : result.toString(),
    };
  }

  return {
    id: 'excise',
    name: title,
    rate: selectedExcise?.ValueDetail
      ? `${selectedExcise.ValueDetail.ValueCount}${selectedExcise.ValueDetail.ValueCurrency}/${selectedExcise.ValueDetail.ValueUnit}`
      : '0',
    rublesAmount: result.toString(),
  };
}

export { getExciseCalc };
