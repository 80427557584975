import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import PlusIcon from '@components/icons/PlusIcon';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Service, ServiceFileData } from '@onlog-public/additional-services-types';
import ServiceComp from '@pages/AdditionalServices/components/Service';
import FileList from '@pages/AdditionalServices/components/Service/FileList';
import serviceSortFunc from '@pages/AdditionalServices/components/Service/serviceSortFunc';
import GroupTotalPrice from '@pages/AdditionalServices/components/ServiceDirectory/GroupTotalPrice';
import isServiceInAdditionGroup from '@pages/AdditionalServices/components/ServiceDirectory/isServiceInAdditionGroup';
import { ServiceDirectoryProps } from '@pages/AdditionalServices/components/ServiceDirectory/types';
import restoreServiceData from '@pages/AdditionalServices/containers/methods/basket/restoreServiceData';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentTotal } from '../Service/CustomsPaymentsTemplate/CustomsPaymentTotal';
import { DefaultCustomsPaymentsProduct } from '../Service/CustomsPaymentsTemplate/DefaultCustomsPaymentsProduct';
import { validateCustomsPaymentProducts } from '../Service/CustomsPaymentsTemplate/helpers/helpers';
import { CustomsPaymentsTemplateStore } from '../Service/CustomsPaymentsTemplate/types';

/**
 * getFilesFromServices возвращает все файлы, добавленные в дочерние услуги
 * @param service
 */
const getFilesFromServices = (service: Service): ServiceFileData[] => {
  return [
    ...service.file_id,
    ...(service.additionServices?.map((s) => getFilesFromServices(s)) ?? []).flat(1),
  ];
};

/**
 * GroupTypeTemplate реализует шаблон вывода групп услуг
 * @param props
 */
const CustomsPaymentsGroupTemplate: FC<ServiceDirectoryProps> = (props) => {
  const {
    variant,
    services,
    serviceCalculations,
    selectedServices,
    globalFiles,
    globalProperties,
    serviceProperties,
    propertyValues,
    propertyOptions,
    serviceTemplateStore,
    onChangeServiceFieldValue,
    onAddGroupToCart,
    onChangeServiceTemplateStore,
    onCreateGroupPDF,
    order,
    selectedProductUUID,
    isOrderDisplay,
    ...baseServiceProps
  } = props;

  const { t } = useTranslation('AdditionalService');

  const files: { [K in string]: ServiceFileData } = {};
  Array.from([
    ...globalFiles,
    ...variant.file_id,
    ...services.map((s) => getFilesFromServices(s)).flat(1),
  ]).map((f) => (files[f.id] = f));
  const filesToDisplay = Object.values(files).sort((a, b) =>
    a.name_original > b.name_original ? 1 : -1
  );

  const primaryServices = services
    .filter((s) => s.is_active && !s.is_broken)
    .filter((s) => !isServiceInAdditionGroup(s))
    .sort(serviceSortFunc);

  const customsPaymentsServices = primaryServices.filter(
    (service) => service.template === 'customsPayments'
  );

  const otherServices = primaryServices.filter((service) => service.template !== 'customsPayments');

  const orderProducts =
    [...(order?.products ?? []), ...(order?.pre_order_products ?? [])]
      ?.map((p) => restoreServiceData(p))
      .filter((d) => !!d) ?? [];

  const existProduct = orderProducts.find(
    (p) => p.setUpService.SelectedProductUUID === selectedProductUUID
  );
  const orderButtonText =
    !!existProduct || isOrderDisplay
      ? t('AdditionalService:Templates.group.alreadyInOrder')
      : t('AdditionalService:Templates.group.addToOrder');

  const [isAddToOrderClicked, setIsAddToOrderClicked] = useState(false);

  const customsPaymentsServiceIDS = primaryServices
    .filter((service) => service.template === 'customsPayments')
    .map((s) => [s.id, ...s.additionServices.map((s) => s.id)])
    .flat();

  const otherServiceIDS = primaryServices
    .filter((service) => service.template !== 'customsPayments')
    .map((s) => [s.id, ...s.additionServices.map((s) => s.id)])
    .flat();

  const customsPaymentService = customsPaymentsServices?.[0];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[customsPaymentService?.id];

  const fields = [...baseServiceProps.servicesFields, ...baseServiceProps.globalFields];
  const fieldValues = {
    ...baseServiceProps.globalFieldValues,
    ...baseServiceProps.servicesFieldValues,
  };

  const highlightInvalidFieldsHandler = (filterErrorKeys: string[]) => {
    const productErrors = validateCustomsPaymentProducts(
      store.products,
      filterErrorKeys,
      customsPaymentService,
      fields,
      fieldValues
    );

    const productsWithErrors = store.products.map((product, index) => ({
      ...product,
      invalidFields: productErrors[index],
    }));

    onChangeServiceTemplateStore(variant.id, customsPaymentService.id, {
      ...store,
      products: productsWithErrors,
    });

    return productsWithErrors;
  };

  const addCustomsPaymentProduct = () => {
    const newProduct = new DefaultCustomsPaymentsProduct();
    const products = [...store.products, newProduct];

    const productErrors = validateCustomsPaymentProducts(
      products,
      ['weight'],
      customsPaymentService,
      fields,
      fieldValues
    );

    const productsWithErrors = products.map((product, index) => ({
      ...product,
      invalidFields: productErrors[index],
    }));

    onChangeServiceTemplateStore(variant.id, customsPaymentService.id, {
      ...store,
      products: productsWithErrors,
    });
  };

  return (
    <>
      <div className="a-service-template-group">
        {customsPaymentsServices.length > 0 && (
          <div className="a-service-template-group-block">
            {customsPaymentsServices.map((s) => {
              const servicesID = [s.id, ...s.additionServices.map((s) => s.id)];
              return (
                <ServiceComp
                  key={s.id}
                  {...baseServiceProps}
                  variant={variant}
                  serviceTemplateStore={serviceTemplateStore}
                  service={s}
                  selectedServices={selectedServices}
                  globalFiles={globalFiles}
                  properties={[...globalProperties, ...serviceProperties]}
                  propertyValues={propertyValues}
                  propertyOptions={propertyOptions}
                  order={order}
                  isOrderDisplay={isOrderDisplay}
                  serviceCalculations={serviceCalculations.filter((c) =>
                    servicesID.includes(c.serviceId)
                  )}
                  onChangeServiceTemplateStore={onChangeServiceTemplateStore}
                  onChangeServiceFieldValue={(f, v) => onChangeServiceFieldValue(variant.id, f, v)}
                />
              );
            })}
          </div>
        )}
        {!isOrderDisplay && (
          <IconButton
            title={`+ Добавить товар`}
            className={'add-new-product'}
            onClick={addCustomsPaymentProduct}
          >
            <PlusIcon fontSize={isMobile ? 'small' : 'medium'} style={{ marginRight: '10px' }} />{' '}
            <span className="add-product-text">{t('CustomsPayments:AddProductBtn')}</span>
          </IconButton>
        )}
        {!isOrderDisplay && customsPaymentService && (
          <CustomsPaymentTotal
            {...baseServiceProps}
            variant={variant}
            service={customsPaymentService}
            selectedServices={selectedServices}
            serviceTemplateStore={serviceTemplateStore}
            globalFiles={globalFiles}
            properties={[...globalProperties, ...serviceProperties]}
            propertyValues={propertyValues}
            propertyOptions={propertyOptions}
            order={order}
            isOrderDisplay={isOrderDisplay}
            serviceCalculations={serviceCalculations.filter((c) =>
              customsPaymentsServiceIDS.includes(c.serviceId)
            )}
            onChangeServiceTemplateStore={onChangeServiceTemplateStore}
            onChangeServiceFieldValue={(f, v) => onChangeServiceFieldValue(variant.id, f, v)}
          />
        )}
        {otherServices.length > 0 && (
          <>
            <div className="a-service-template-group-block">
              {otherServices.map((s) => {
                const servicesID = [s.id, ...s.additionServices.map((s) => s.id)];
                return (
                  <ServiceComp
                    key={s.id}
                    {...baseServiceProps}
                    variant={variant}
                    serviceTemplateStore={serviceTemplateStore}
                    service={s}
                    selectedServices={selectedServices}
                    globalFiles={globalFiles}
                    properties={[...globalProperties, ...serviceProperties]}
                    propertyValues={propertyValues}
                    propertyOptions={propertyOptions}
                    order={order}
                    isOrderDisplay={isOrderDisplay}
                    serviceCalculations={serviceCalculations.filter((c) =>
                      servicesID.includes(c.serviceId)
                    )}
                    onChangeServiceTemplateStore={onChangeServiceTemplateStore}
                    onChangeServiceFieldValue={(f, v) =>
                      onChangeServiceFieldValue(variant.id, f, v)
                    }
                  />
                );
              })}
            </div>
            <GroupTotalPrice
              langID={props.langID}
              currencyID={props.currencyID}
              selectedServices={selectedServices}
              serviceCalculations={serviceCalculations.filter((c) =>
                otherServiceIDS.includes(c.serviceId)
              )}
              mainDirectory={props.mainDirectory}
              serviceDataCache={props.serviceDataCache}
            />
          </>
        )}
        {!isOrderDisplay && (
          <>
            <div className="a-service-template-group-buttons">
              <BitrixDisableUserGuard>
                <Button
                  onClick={() => onCreateGroupPDF()}
                  color={'secondary'}
                  variant={'outlined'}
                  size={'large'}
                >
                  {t('CartAsideBlock:DownloadPDF')}
                </Button>
              </BitrixDisableUserGuard>
              <BitrixDisableUserGuard>
                <div
                  className={clsx('button', {
                    'in-order': !!existProduct || isOrderDisplay,
                    loading: isAddToOrderClicked,
                  })}
                  onClick={() => {
                    const products = highlightInvalidFieldsHandler([]);
                    const hasErrors = products.some((product) => product.invalidFields.length > 0);

                    if (isAddToOrderClicked || !!existProduct || isOrderDisplay || hasErrors) {
                      return;
                    }

                    setIsAddToOrderClicked(true);
                    onAddGroupToCart()
                      .catch(() => null)
                      .finally(() => {
                        setIsAddToOrderClicked(false);
                      });
                  }}
                >
                  <div>
                    <CircularProgress color="inherit" size={16} />
                  </div>
                  <div>{orderButtonText}</div>
                </div>
              </BitrixDisableUserGuard>
            </div>
            {filesToDisplay.length > 0 && <FileList files={filesToDisplay} />}
          </>
        )}
      </div>
    </>
  );
};

export default CustomsPaymentsGroupTemplate;
