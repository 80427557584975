import { ExportItem, ImportItem } from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

import { CustomsPaymentsTemplateProduct } from '../types';
import { getCalculation } from './calculation';

interface getAntidumpingFeeCalc {
  selectedFee: ImportItem;
  selectedExportFee: ExportItem;
  currencies: CurrencyData[];
  contractCurrency: CurrencyData;
  product: CustomsPaymentsTemplateProduct;
  title: string;
  cost: number;
}

function getAntidumpingFeeCalc({
  selectedFee,
  selectedExportFee,
  currencies,
  contractCurrency,
  product,
  title,
  cost,
}: getAntidumpingFeeCalc) {
  const { productsCount } = product;

  let feeRowData: any = null;
  if (selectedFee?.Value === '0' || !selectedFee?.ValueDetail) {
    feeRowData = {
      id: 'antidumping_fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
    };
  }

  const foundCurrency = currencies.find(
    (currency) => currency.code === selectedFee?.ValueDetail?.ValueCurrency
  );

  const count = selectedFee?.ValueDetail?.ValueCount ?? 0;
  const unit = selectedFee?.ValueDetail.ValueUnit ?? '';
  const localCurrency = selectedFee?.ValueDetail?.ValueCurrency ?? '';
  const feeBase = +productsCount;
  const result = getCalculation(
    +count,
    unit,
    localCurrency,
    foundCurrency?.course ?? 1,
    cost,
    feeBase
  );

  feeRowData = {
    id: 'antidumping_fee',
    name: title,
    rate: selectedFee?.Value.split('|')[0],
    rublesAmount: result,
  };

  if (!selectedFee) {
    feeRowData = null;
  }

  let exportFeeRowData: any = null;
  if (selectedExportFee?.Value === '0' || !selectedExportFee?.ValueDetail) {
    exportFeeRowData = {
      id: 'antidumping_fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
    };
  }

  const foundExportCurrency =
    currencies.find((currency) => currency.code === selectedExportFee?.ValueDetail?.ValueCurrency) ||
    contractCurrency;

  const countExport = +selectedExportFee?.ValueDetail?.ValueCount;
  const unitExport = selectedExportFee?.ValueDetail?.ValueUnit || '';
  const localCurrencyExport = selectedExportFee?.ValueDetail?.ValueCurrency ?? '';

  const resultExport = getCalculation(
    countExport,
    unitExport,
    localCurrencyExport,
    foundExportCurrency?.course ?? 1,
    cost,
    cost
  );

  exportFeeRowData = {
    id: 'antidumping_fee',
    name: title,
    rate: selectedExportFee?.Value.split('|')[0],
    rublesAmount: resultExport,
  };

  return [feeRowData, exportFeeRowData];
}

export { getAntidumpingFeeCalc };
