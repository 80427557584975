import Typography from '@mui/material/Typography';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProps } from '../../types';
import { getAntidumpingFeeCalc } from '../helpers/getAntidumpingFeeCalc';
import { getBaseCalc } from '../helpers/getBaseCalc';
import { getExciseCalc } from '../helpers/getExciseCalc';
import { getFeeCalc } from '../helpers/getFeeCalc';
import { getTotalCalc } from '../helpers/getTotalCalc';
import { getVatCalc } from '../helpers/getVatCalc';
import BaseTotalTable from '../Tables/BaseTotal/BaseTotalTable';
import BaseTotalTableHeader from '../Tables/BaseTotal/BaseTotalTableHeader';
import TotalTable from '../Tables/Total/TotalTable';
import TotalTableHeader from '../Tables/Total/TotalTableHeader';
import { CustomsPaymentsTemplateProduct, CustomsPaymentsTemplateStore } from '../types';

interface CustomsPaymentsDetailingResultProps extends ServiceProps {
  product: CustomsPaymentsTemplateProduct;
  productIndex: number;
  currencies: CurrencyData[];
}

const objectStyles = {
  color: '#000',
  fontSize: '14px',
};

function CustomsPaymentsDetailingResult({
  product,
  servicesFieldValues,
  servicesFields,
  globalFieldValues,
  globalFields,
  service,
  serviceTemplateStore,
  options,
  productIndex,
  currencies,
}: CustomsPaymentsDetailingResultProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const {
    productsCount,
    packagesCount,
    maximumRetailPackagePrice,
    selectedFee,
    selectedAntidumpingFee,
    selectedExcise,
    selectedExportFee,
    selectedVat,
  } = product;

  const fields = [...servicesFields, ...globalFields];
  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];

  const contractCurrencyField = fields.find((f) => f.code === settings.ContractCurrencyField);
  const contractCurrency = currencies.find(
    (currency) => currency.id === fieldValues[contractCurrencyField.code].toString()
  );
  const customsModeField = fields.find((f) => f.code === settings.CustomsModeField);
  const customsMode =
    options[customsModeField.code].find(
      (option) => option.value === fieldValues[customsModeField.code]
    )?.origin?.value ?? 1;

  const borderDeliveryCurrencyField = fields.find(
    (f) => f.code === settings.BorderDeliveryCurrencyField
  );
  const otherExpensesCurrencyField = fields.find(
    (f) => f.code === settings.OtherExpensesCurrencyField
  );
  const borderDeliverySumField = fields.find((f) => f.code === settings.BorderDeliverySumField);
  const otherExpensesSumField = fields.find((f) => f.code === settings.OtherExpensesSumField);
  const borderDeliveryCurrency = currencies.find(
    (currency) => currency.id === fieldValues[borderDeliveryCurrencyField.code].toString()
  );
  const otherExpensesCurrency = currencies.find(
    (currency) => currency.id === fieldValues[otherExpensesCurrencyField.code].toString()
  );

  const totalProductsWeight = store.products.reduce((prev, item) => prev + +item.weight, 0);
  const totalContractCurrencyCost = store.products.reduce(
    (prev, item) => prev + +item.contractCurrencyCost,
    0
  );

  const isExport = customsMode === 2;

  const baseResult = getBaseCalc({
    productContractCurrencyCost: +product.contractCurrencyCost,
    contractCurrency: contractCurrency,
    borderDeliveryCost: fieldValues[borderDeliverySumField.code],
    borderDeliveryCurrency: borderDeliveryCurrency,
    productWeight: +product.weight,
    otherExpensesCost: fieldValues[otherExpensesSumField.code],
    otherExpensesCurrency: otherExpensesCurrency,
    productCost: +product.contractCurrencyCost,
    totalContractCurrencyCost: totalContractCurrencyCost,
    totalProductsWeight: totalProductsWeight,
    contractCurrencyLabel: t('CustomsPayments:ContractCurrencyPlaceholder'),
    borderDeliveryLabel: t('CustomsPayments:BorderDeliveryLabel'),
    otherExpensesLabel: t('CustomsPayments:OtherExpenses'),
    baseTotalLabel: t('CustomsPayments:BaseTotalLabel'),
  });

  const [feeRowData, exportFeeRowData] = getFeeCalc({
    contractCurrency,
    currencies,
    product,
    selectedExportFee,
    selectedFee,
    title: t('CustomsPayments:DutyGroupTitle'),
    cost: baseResult[baseResult.length - 1].rublesAmount,
  });

  const [antidumpingFeeRowData] = getAntidumpingFeeCalc({
    contractCurrency: contractCurrency,
    currencies,
    product,
    selectedExportFee,
    selectedFee: selectedAntidumpingFee,
    title: t('CustomsPayments:AntidumpingDutyRowTitle'),
    cost: baseResult[baseResult.length - 1].rublesAmount,
  });

  const exciseRowData = getExciseCalc({
    product,
    selectedExcise,
    title: t('CustomsPayments:ExciseGroupTitle'),
    cost: baseResult[baseResult.length - 1].rublesAmount,
    currencies,
  });

  const vatRowData = getVatCalc({
    exciseRowData,
    feeRowData,
    cost: baseResult[baseResult.length - 1].rublesAmount,
    selectedVat,
    title: t('CustomsPayments:VatGroupTitle'),
  });

  const result = getTotalCalc({
    antidumpingFeeRowData,
    exciseRowData,
    exportFeeRowData,
    feeRowData,
    vatRowData,
    isExport,
    title: t('CustomsPayments:TotalCustomsPaymentsRowTitle'),
    products: store.products,
    productIndex,
  });

  const isProductsCountFieldVisible = [
    selectedFee?.ValueDetail?.ValueUnit,
    selectedFee?.ValueDetail?.ValueUnit2,
    selectedFee?.ValueDetail?.ValueUnit3,
    selectedFee?.ValueDetailAdd?.ValueUnit,
    selectedExportFee?.ValueDetail?.ValueUnit,
    selectedExportFee?.ValueDetail?.ValueUnit2,
  ]
    .filter(Boolean)
    .reduce((_, unit) => !unit.includes('%'), false);

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';
  const isBaseCountVisible =
    !selectedExportFee ||
    (!!selectedExportFee && !selectedExportFee?.Value.includes('Беспошлинно'));
  const isExciseCountFieldVisible =
    !!selectedExcise && selectedExcise?.Measure?.Razm !== selectedFee?.Measure?.Razm;

  const feeMeasureText =
    selectedFee && typeof selectedFee.Measure.Name === 'string'
      ? ` ${selectedFee.Measure.Name}(${selectedFee.Measure.Razm})`
      : ``;

  const exciseMeasureText = selectedExcise
    ? ` ${selectedExcise?.Measure?.Name}(${selectedExcise?.Measure?.Razm})`
    : ``;

  return (
    <>
      <div className="count-wrap">
        {isBaseCountVisible && isProductsCountFieldVisible && (
          <div className="result-item__col">
            <div className="result-item__param">
              <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                {t('CustomsPayments:ProductQuantityLabel')}
                {feeMeasureText}
              </Typography>

              <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                {productsCount}
              </Typography>
            </div>
          </div>
        )}
        {isExciseCountFieldVisible && (
          <div className="result-item__col">
            <div className="result-item__param">
              <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                {t('CustomsPayments:ExciseQuantityLabel')}
                {exciseMeasureText}
              </Typography>

              <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                {productsCount}
              </Typography>
            </div>
          </div>
        )}
        {isSpecialFieldsVisible && (
          <div className="result-item__col">
            <div className="result-item__param">
              <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                {t('CustomsPayments:PackagesCount')}
                {typeof selectedExcise.Measure.Razm === 'string' &&
                  `(${selectedExcise.Measure.Razm})`}
              </Typography>

              <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                {packagesCount}
              </Typography>
            </div>
          </div>
        )}
        {isSpecialFieldsVisible && (
          <div className="result-item__col">
            <div className="result-item__param">
              <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                {t('CustomsPayments:PackageMaxPriceLabel')}
              </Typography>

              <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                {maximumRetailPackagePrice}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className="result-wrap kendo-pdf--prevent-split">
        <div className="result-item">
          <div className="result-item__table">
            <div className="a-service-template-customsPayments-base kendo-pdf--prevent-split">
              <div className="a-service-template-customsPayments-base-list">
                <div className="a-service-template-customsPayments-base-scroll">
                  <BaseTotalTableHeader />
                  <BaseTotalTable data={baseResult} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="result-item">
          <div className="result-item__table base-total">
            <div className="a-service-template-customsPayments kendo-pdf--prevent-split">
              <div className="a-service-template-customsPayments-list">
                <div className="a-service-template-customsPayments-scroll">
                  <TotalTableHeader />
                  <TotalTable data={result} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { CustomsPaymentsDetailingResult };
