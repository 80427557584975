import MRadio from '@assets/mui/@material-extend/MRadio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { AdditionalTax } from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/components/CustomsPaymentsProductItem/types';
import {
  ExciseItem,
  ExportItem,
  ImportItem,
  VatItem,
} from '@services/requests/customsTaxService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentsTemplateProduct } from '../types';

interface AdditionalTaxProps {
  additionalTax: AdditionalTax;
  selectedFee: ImportItem;
  selectedExportFee: ExportItem;
  selectedAntidumpingFee: ImportItem | null;
  selectedVat: VatItem;
  selectedExcise: ExciseItem;
  onChangeProductValue: <T extends keyof CustomsPaymentsTemplateProduct>(
    key: T,
    value: CustomsPaymentsTemplateProduct[T]
  ) => void;
  onHighlightInvalidFields: (filterErrorKeys: string[]) => string[];
}

let vatDirectory = '';

function AdditionalTaxList({
  additionalTax,
  selectedFee,
  selectedVat,
  selectedExportFee,
  selectedExcise,
  selectedAntidumpingFee,
  onChangeProductValue,
  onHighlightInvalidFields,
}: AdditionalTaxProps) {
  const { t } = useTranslation(['CustomsPayments']);

  return (
    <div className="additional-tax-wrap">
      {!!additionalTax.exportFees.length && (
        <div className="additional-tax-item fee-wrap">
          <span className="additional-tax-item__title">{t('CustomsPayments:DutyGroupTitle')}</span>
          <div className="additional-tax-item__body">
            {additionalTax.exportFees.length === 1 &&
              additionalTax.exportFees[0]?.Value.includes('Беспошлинно') && (
                <span className="additional-tax-text">Беспошлинно</span>
              )}
            {additionalTax.exportFees.length >= 1 &&
              !additionalTax.exportFees[0]?.Value.includes('Беспошлинно') && (
                <RadioGroup aria-label={'Export Fee'} name={'export-fee'} sx={{ gap: '15px' }}>
                  {additionalTax.exportFees
                    .filter((fee) => fee?.Note !== 'Антидемпинговая пошлина')
                    .map((fee, index) => (
                      <FormControlLabel
                        key={'fee-' + fee.ValueDetail + '-' + index}
                        className="tax-label"
                        control={
                          <MRadio
                            size={'small'}
                            className={'radio'}
                            checked={selectedExportFee.Value === fee.Value}
                            onChange={() => {
                              onChangeProductValue('selectedExportFee', fee);
                              onHighlightInvalidFields([
                                'productsCount',
                                'packagesCount',
                                'maximumRetailPackagePrice',
                                'exciseCount',
                              ]);
                            }}
                            sx={{
                              padding: '5px 8px',
                            }}
                          />
                        }
                        classes={{
                          label: 'radio-label',
                        }}
                        label={
                          <span key={'fee-text-' + index} className="additional-tax-text">
                            {fee.Value.split('|')[0]}
                            <span dangerouslySetInnerHTML={{ __html: fee.Prim }}></span>
                            {fee.Order && fee.Link && (
                              <>
                                {' '}
                                (
                                <a href={fee.Link} target="_blank" rel="noopener noreferrer">
                                  {fee.Order}
                                </a>
                                )
                              </>
                            )}
                          </span>
                        }
                      />
                    ))}
                </RadioGroup>
              )}
          </div>
        </div>
      )}
      {!!additionalTax.fees.length && (
        <div className="additional-tax-item fee-wrap">
          <span className="additional-tax-item__title">{t('CustomsPayments:DutyGroupTitle')}</span>
          <div className="additional-tax-item__body">
            <RadioGroup aria-label={'fee'} name={'fee'} sx={{ gap: '15px' }}>
              {additionalTax.fees
                .filter((fee) => fee?.Note !== 'Антидемпинговая пошлина')
                .map((fee, index) => (
                  <FormControlLabel
                    key={'fee-' + fee.ValueDetail + '-' + index}
                    className="tax-label"
                    control={
                      <MRadio
                        size={'small'}
                        className={'radio'}
                        checked={selectedFee.Value === fee.Value}
                        onChange={() => {
                          onChangeProductValue('selectedFee', fee);
                          onHighlightInvalidFields([
                            'productsCount',
                            'packagesCount',
                            'maximumRetailPackagePrice',
                            'exciseCount',
                          ]);
                        }}
                        sx={{
                          padding: '5px 8px',
                        }}
                      />
                    }
                    classes={{
                      label: 'radio-label',
                    }}
                    label={
                      <span key={'fee-text-' + index} className="additional-tax-text">
                        {fee.Value.split('|')[0]}
                        <span dangerouslySetInnerHTML={{ __html: fee.Prim }}></span>
                        {fee.Order && fee.Link && (
                          <>
                            {' '}
                            (
                            <a href={fee.Link} target="_blank" rel="noopener noreferrer">
                              {fee.Order}
                            </a>
                            )
                          </>
                        )}
                      </span>
                    }
                  />
                ))}
            </RadioGroup>
            <RadioGroup aria-label={'НДС'} name={'antidumping_vat'} sx={{ gap: '15px' }}>
              {additionalTax.fees
                .filter((fee) => fee?.Note === 'Антидемпинговая пошлина')
                .map((fee, index) => (
                  <React.Fragment key={'fee-' + fee.ValueDetail + '-' + index}>
                    {index === 0 && <span>{fee?.Note}</span>}
                    <FormControlLabel
                      className="tax-label"
                      control={
                        <MRadio
                          size={'small'}
                          className={'radio'}
                          checked={selectedAntidumpingFee.Value === fee.Value}
                          onChange={() => {
                            onChangeProductValue('selectedAntidumpingFee', fee);
                            onHighlightInvalidFields([
                              'productsCount',
                              'packagesCount',
                              'maximumRetailPackagePrice',
                              'exciseCount',
                            ]);
                          }}
                          sx={{
                            padding: '5px 8px',
                          }}
                        />
                      }
                      classes={{
                        label: 'radio-label',
                      }}
                      label={
                        <span key={'fee-text-' + index} className="additional-tax-text">
                          {fee.Value.split('|')[0]}
                          <span dangerouslySetInnerHTML={{ __html: fee.Prim }}></span>
                          {fee.Order && fee.Link && (
                            <>
                              {' '}
                              (
                              <a href={fee.Link} target="_blank" rel="noopener noreferrer">
                                {fee.Order}
                              </a>
                              )
                            </>
                          )}
                        </span>
                      }
                    />
                  </React.Fragment>
                ))}
            </RadioGroup>
          </div>
        </div>
      )}
      {!!additionalTax.vats.length && (
        <div className="additional-tax-item vat-wrap">
          <span className="additional-tax-item__title">{t('CustomsPayments:VatGroupTitle')}</span>
          <div className="additional-tax-item__body">
            <RadioGroup aria-label={'НДС'} name={'vat'} sx={{ gap: '15px' }}>
              {additionalTax.vats.map((vat) => {
                let directoryContent = <></>;

                if (
                  vat?.Directory &&
                  vatDirectory !== vat?.Directory.RuName &&
                  typeof vat.Directory.RuName === 'string'
                ) {
                  directoryContent = <span>{vat.Directory.RuName}</span>;
                  vatDirectory = vat.Directory.RuName;
                }

                return (
                  <React.Fragment key={'vat-' + vat.Value}>
                    {directoryContent}
                    <FormControlLabel
                      className="tax-label"
                      control={
                        <MRadio
                          size={'small'}
                          className={'radio'}
                          checked={selectedVat.Value === vat.Value}
                          onChange={() => {
                            onChangeProductValue('selectedVat', vat);
                            onHighlightInvalidFields([
                              'productsCount',
                              'packagesCount',
                              'maximumRetailPackagePrice',
                              'exciseCount',
                            ]);
                          }}
                          sx={{
                            padding: '5px 8px',
                          }}
                        />
                      }
                      classes={{
                        label: 'radio-label',
                      }}
                      label={`${vat.ValueDetail.ValueCount} ${vat.ValueDetail.ValueUnit}${
                        typeof vat.Condition === 'string' ? ` ${vat.Condition}` : ''
                      }`}
                    />
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </div>
        </div>
      )}
      <div className="additional-tax-item excise-tax-wrap">
        <span className="additional-tax-item__title">{t('CustomsPayments:ExciseGroupTitle')}</span>
        <div className="additional-tax-item__body">
          {!additionalTax.excises.length && (
            <span className="additional-tax-text">{t('CustomsPayments:ExciseNoTax')}</span>
          )}
          {!!additionalTax.excises.length && (
            <RadioGroup aria-label={'НДС'} name={'vat'} sx={{ gap: '15px' }}>
              {additionalTax.excises.map((excise) => (
                <FormControlLabel
                  className="tax-label"
                  key={'vat-' + excise.Value}
                  control={
                    <MRadio
                      size={'small'}
                      className={'radio'}
                      checked={selectedExcise.Value === excise.Value}
                      onChange={() => {
                        onChangeProductValue('selectedExcise', excise);
                        onHighlightInvalidFields([
                          'productsCount',
                          'packagesCount',
                          'maximumRetailPackagePrice',
                          'exciseCount',
                        ]);
                      }}
                      sx={{
                        padding: '5px 8px',
                      }}
                    />
                  }
                  classes={{
                    label: 'radio-label',
                  }}
                  label={`${excise.Value.split('|')[0]}${
                    typeof excise.Condition === 'string' ? ` ${excise.Condition}` : ''
                  }`}
                />
              ))}
            </RadioGroup>
          )}
        </div>
      </div>
    </div>
  );
}

export { AdditionalTaxList };
