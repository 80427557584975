import { CircularProgress } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { ServiceFieldSettings } from '@onlog-public/additional-services-types';
import { SelectOption } from '@pages/AdditionalServices/containers/services/optionsGenerator/types';
import { customsTaxService } from '@services/requests/customsTaxService';
import { CountriesResponse } from '@services/requests/customsTaxService/interface';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

export interface SelectAltaCountryFieldProps {
  fieldCode: string;
  defaultValue?: number;
  value?: number;
  settings: ServiceFieldSettings;
  isFieldsDisabled: boolean;
  tooltip: string;
  placeholder: string;
  options?: SelectOption[];
  isOptionsLoading: boolean;
  error?: string;

  onChange: (value: number) => void;
}

const SelectAltaCountryField: FC<SelectAltaCountryFieldProps> = (props) => {
  const {
    fieldCode,
    value,
    settings,
    tooltip,
    placeholder,
    options = [],
    error = '',
    isOptionsLoading,
    isFieldsDisabled,
    onChange,
  } = props;

  const [countries, setCountries] = useState<CountriesResponse>([]);

  const optionsFormattedCountries = countries.map((country) => ({
    value: country.Code,
    label: country.Name[0] + country.Name.slice(1).toLowerCase(),
    origin: country,
    description: '',
  }));

  useEffect(() => {
    customsTaxService().LoadCountries().then(setCountries);
  }, []);

  const fieldSize = settings.FieldSize === 'small' ? 'small' : 'medium';
  const loaderSize = settings.FieldSize === 'small' ? 16 : 24;
  const isWithDescription = !!options.find((o) => !!o.description);
  const existCountry = optionsFormattedCountries.find((country) => {
    return (
      country.value ===
      (/^\d+$/.test(value.toString()) ? ('000' + value).slice(-3) : value.toString())
    );
  });

  return (
    <Tooltip
      placement="top"
      title={
        tooltip.length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: tooltip }} className="a-html-content" />
        )
      }
    >
      <FormControl variant="outlined" fullWidth={true} error={error.length > 0}>
        <InputLabel id={`${fieldCode}-label`}>{placeholder}</InputLabel>
        <Select
          labelId={`${fieldCode}-label`}
          id={`${fieldCode}-select`}
          value={String(existCountry?.value || '')}
          className={clsx('a-service-field-selector', fieldSize, {
            'with-description': isWithDescription,
          })}
          fullWidth
          size={fieldSize}
          label={placeholder}
          disabled={isFieldsDisabled || settings.IsNeedDisableField}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          endAdornment={
            isOptionsLoading && (
              <div className={clsx('a-service-field-selector-loader', fieldSize)}>
                <CircularProgress color="inherit" size={loaderSize} />
              </div>
            )
          }
          onChange={(e) => onChange(parseInt(e.target.value))}
        >
          {optionsFormattedCountries.map((option, index) => (
            <MenuItem
              value={option.value}
              key={option.value + '-' + index}
              className="a-service-field-selector-option--root"
            >
              <div
                className={clsx('a-service-field-selector-option', {
                  'with-description': isWithDescription,
                  [fieldSize]: true,
                })}
              >
                <div className="option-label">{option.label}</div>
                {option.description && (
                  <div className="option-description">{option.description}</div>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
        {error.length > 0 && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Tooltip>
  );
};

export default SelectAltaCountryField;
