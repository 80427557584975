export const LOCALIZATION_DEPENDENCY = [
  {
    id: '1',
    currencyCode: 'RUB (RU)',
    countryCode: 'ru',
    secondaryLanguageId: '2',
  },
  {
    id: '2',
    currencyCode: 'USD',
    countryCode: 'en',
    secondaryLanguageId: '1',
  },
  {
    id: '2',
    currencyCode: 'EUR',
    countryCode: 'en',
    secondaryLanguageId: '1',
  },
  {
    id: '11',
    currencyCode: 'CNY',
    countryCode: 'cn',
    secondaryLanguageId: '2',
  },
  {
    id: '2',
    currencyCode: 'CHF',
    countryCode: 'ch',
    secondaryLanguageId: '1',
  },
  {
    id: '7',
    currencyCode: 'USD',
    countryCode: 'es',
    secondaryLanguageId: '2',
  },
];
