import getLocalizationByArgs from '@helpers/getLocalizationByArgs';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import { Sbor, Sbor2023 } from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProps } from '../../types';
import { getSborCalc } from '../helpers/getSborCalc';
import { moneyRounding } from '../helpers/price';
import { CustomsPaymentsTemplateStore } from '../types';
import { CustomsPaymentTotalContainer } from './StyledComponents';

function CustomsPaymentTotal({
  serviceTemplateStore,
  service,
  servicesFields,
  serviceCalculations,
  serviceDataCache,
  onChangeServiceFieldValue,
}: ServiceProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const totalCostField = servicesFields.find((f) => f.code === settings.TotalCostField);

  const totalProducts = store.products.reduce(
    (prev, product) => {
      const { antiDumpingSum = 0, exciseSum = 0, feeSum = 0, vatSum = 0 } = product.total;
      return {
        feeSum: prev.feeSum + feeSum,
        exciseSum: prev.exciseSum + exciseSum,
        antiDumpingSum: prev.antiDumpingSum + antiDumpingSum,
        vatSum: prev.vatSum + vatSum,
      };
    },
    {
      feeSum: 0,
      exciseSum: 0,
      antiDumpingSum: 0,
      vatSum: 0,
    }
  );

  const customsSum = store.products.reduce((prev, item) => prev + (item.total?.customsSum ?? 0), 0);

  const sbor = getSborCalc({ products: store.products, isTotalSbor: true });

  const totalResult = Object.values(totalProducts).reduce(
    (prev, total) => moneyRounding(prev + (total || 0)),
    0
  );

  useEffect(() => {
    if (!totalCostField) {
      return;
    }

    onChangeServiceFieldValue(totalCostField.code, totalResult + sbor);
  }, [totalResult, sbor]);

  const totalCalculation = serviceCalculations.find((calc) => calc.serviceId === service.id);
  const totalCurrency: CurrencyData =
    serviceDataCache.currency.cache[String(totalCalculation?.currencyId ?? '')];

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!totalCostField) {
    return <></>;
  }

  return (
    <>
      <Typography variant={'h2'} className="heading">
        {t('CustomsPayments:AllProductsTotalTitle')}
      </Typography>
      <CustomsPaymentTotalContainer className={'cargo-desc'}>
        <ul className="summary-list">
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsSum')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(customsSum, 2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(totalProducts.feeSum, 2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsExcise')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(totalProducts.exciseSum, 2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:AntidumpingDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(totalProducts.antiDumpingSum, 2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsVAT')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(totalProducts.vatSum, 2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsSbor')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {getFormattedPrice(sbor, 2)} RUB
            </Typography>
          </li>
        </ul>
        <div className={'cargo-desc'}>
          <span className="cargo-desc__text">
            {isMobile
              ? t('CustomsPayments:TotalProductsPriceMobile')
              : t('CustomsPayments:TotalProductsPriceDesktop')}
            :
          </span>
          <span className={'cargo-desc__price'}>
            {getFormattedPrice(totalCalculation?.result?.result?.fullPriceInTargetCurrency) ?? 0}{' '}
            {totalCurrency?.code}
          </span>
        </div>
      </CustomsPaymentTotalContainer>
    </>
  );
}

export { CustomsPaymentTotal };
