import { $error } from '@settings/errorContext';
import getEnv from '@settings/getEnv';
import { fetchClientFactory } from '@settings/services/FetchClient';
import { FetchClient } from '@settings/services/FetchClient/interface';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';
import { Collection } from '@settings/services/types';
import { getStringFormattedSearchParams } from 'src/utils/search-params';

import { getFormmatedNodes } from './helpers';
import {
  CalculateRatesParams,
  CalculationRateReturnType,
  CodeItem,
  CountriesResponse,
  CurrencyResponse,
  CustomsTaxServiceInterface,
  FeaturesResponse,
  ModeType,
  SborParams,
  SborResponse,
} from './interface';
import {
  codesResponseSchema,
  countriesResponseSchema,
  currencyResponseSchema,
  exportCalculationRateResponseSchema,
  featuresResponseSchema,
  importCalculationRateResponseSchema,
  sborResponseSchema,
} from './schemas';

// Сервис загрузки данных заказов
export class CustomsTaxService implements CustomsTaxServiceInterface {
  private readonly logger: Logger;

  private readonly client: FetchClient;

  /**
   * Конструктор сервиса
   */
  constructor() {
    const { REACT_APP_ALTA_PROXY_ENDPOINT } = getEnv();
    this.logger = loggerFactory().make(`Customs Tax Service`);
    this.client = fetchClientFactory(REACT_APP_ALTA_PROXY_ENDPOINT);
  }

  async CalculateRates<T extends ModeType>(
    mode: T,
    params: CalculateRatesParams
  ): Promise<CalculationRateReturnType<T>> {
    try {
      this.logger.Debug('Params:', params);

      const { tncode, country, certificate, date } = params;

      const formattedParams: Collection<string | number | Date> = {
        tncode: tncode,
        date: date ? date : new Date(),
        tncountry: country,
        alta_taksa_form_type: mode,
      };

      if (certificate === '1') {
        formattedParams.tncertificate = 'on';
      }

      const stringFormattedDataSearchParams = getStringFormattedSearchParams(formattedParams, true);

      const formattedMainParams: Collection<string | number | Date> = {
        data: stringFormattedDataSearchParams.slice(1),
        [mode === 'export' ? 'expGoodinfo' : 'goodinfo']: 1,
      };

      const stringFormattedSearchParams = getStringFormattedSearchParams(formattedMainParams, true);

      const result = await this.client.Get<string>(stringFormattedSearchParams);

      if (mode === 'export') {
        const validatedExportResult = exportCalculationRateResponseSchema.safeParse(result);

        if (validatedExportResult.success === true) {
          return validatedExportResult.data;
        }
      } else {
        const validatedImportResult = importCalculationRateResponseSchema.safeParse(result);

        if (validatedImportResult.success === true) {
          return validatedImportResult.data;
        }
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadFeatures(params: CalculateRatesParams): Promise<FeaturesResponse | null> {
    try {
      this.logger.Debug('Params:', params);

      const { tncode, country, certificate, date, mode } = params;

      const formattedDataParams: Collection<string | number | Date> = {
        tncode: tncode,
        date: date ? date : new Date(),
        tncountry: country,
        alta_taksa_form_type: mode,
      };

      if (certificate === '1') {
        formattedDataParams.tncertificate = 'on';
      }

      const stringFormattedDataSearchParams = getStringFormattedSearchParams(
        formattedDataParams,
        true
      );

      const formattedMainParams: Collection<string | number | Date> = {
        data: stringFormattedDataSearchParams.slice(1),
        [mode === 'export' ? 'expHint' : 'hint']: 1,
      };

      const stringFormattedSearchParams = getStringFormattedSearchParams(formattedMainParams, true);

      const result = await this.client.Get<string>(stringFormattedSearchParams);
      const formattedNodes = getFormmatedNodes(result);

      const validatedFeatures = featuresResponseSchema.safeParse(formattedNodes);

      if (validatedFeatures.success === true) {
        return validatedFeatures.data;
      }

      return null;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadCurrencies(): Promise<CurrencyResponse> {
    try {
      const stringFormattedSearchParams = getStringFormattedSearchParams({
        get_currency: 1,
        date: new Date(),
      });

      const result = await this.client.Get<unknown>(stringFormattedSearchParams);

      const validatedCurrencies = currencyResponseSchema.safeParse(result);

      if (validatedCurrencies.success === false) {
        throw validatedCurrencies.error;
      } else {
        return validatedCurrencies.data;
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadSbor(params: SborParams): Promise<SborResponse> {
    try {
      this.logger.Debug('Params:', params);

      const { mode, ...restParams } = params;

      const stringFormattedSearchParams = getStringFormattedSearchParams(
        {
          ...restParams,
          date: restParams.date ? restParams.date : new Date(),
          [mode === 'import' ? 'sbor' : 'exSbor']: 1,
        },
        true
      );

      const result = await this.client.Get<unknown>(stringFormattedSearchParams);

      const validatedSbor = sborResponseSchema.safeParse(result);

      if (validatedSbor.success === false) {
        throw validatedSbor.error;
      } else {
        return validatedSbor.data;
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadCountries(): Promise<CountriesResponse> {
    try {
      const stringFormattedSearchParams = getStringFormattedSearchParams({
        countries: 1,
      });

      const result = await this.client.Get<string>(stringFormattedSearchParams);

      const validatedCountries = countriesResponseSchema.safeParse(result);

      if (validatedCountries.success === false) {
        throw validatedCountries.error;
      } else {
        return validatedCountries.data.Country;
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadCodesBySearch(query: string): Promise<CodeItem[] | CodeItem> {
    try {
      const stringFormattedSearchParams = getStringFormattedSearchParams(
        { search: query, limit: 10000 },
        true
      );

      const result = await this.client.Get<string>(stringFormattedSearchParams);

      const validatedCountries = codesResponseSchema.safeParse(result);

      if (validatedCountries.success === false) {
        console.error(validatedCountries.error);
      } else {
        return validatedCountries.data.i;
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadCodesBySearchCode(code: string): Promise<CodeItem[] | CodeItem> {
    try {
      const formattedParams: Collection<string | number | Date> = {
        search_code: code,
        limit: 10000,
      };

      const stringFormattedSearchParams = getStringFormattedSearchParams(formattedParams, true);

      const result = await this.client.Get<string>(stringFormattedSearchParams);

      const validatedCountries = codesResponseSchema.safeParse(result);

      if (validatedCountries.success === false) {
        throw validatedCountries.error;
      } else {
        return validatedCountries.data.i;
      }
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
