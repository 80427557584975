import { ServiceField } from '@onlog-public/additional-services-types';
import AdditionServiceFieldsRow from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/AdditionServiceFieldsRow';
import { AdditionServiceFieldsProps } from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/types';
import React, { FC } from 'react';

import AdditionServiceFieldsAccordion from './AdditionServiceFieldsAccordion';

const AdditionServiceFields: FC<AdditionServiceFieldsProps> = (props) => {
  const {
    templateSettings,
    options,
    langID,
    fields,
    fieldValues,
    isOptionsLoading,
    isFieldsDisabled,
    onChange,
    validation,
  } = props;

  const courseFields = fields.filter(
    (f) => f.settings.DefaultSettings.NumberFieldSettings.IsCourseRequired
  );

  fields
    .filter((f) => f.settings.DefaultSettings.NumberFieldSettings.IsCurrencyCourseField)
    .forEach((f) => {
      const currencies = courseFields.map((c) => fieldValues[c.settings.DefaultSettings.NumberFieldSettings.CurrencyField]);
      const fieldCurrency = fieldValues[f.settings.DefaultSettings.NumberFieldSettings.CurrencyField];

      if (!currencies.includes(fieldCurrency)) {
        courseFields.push(f);
      }
    });

  const courseFieldsCodes = courseFields.map((f) => f.code);

  const rows: { [T in number]: { idx: number; fields: ServiceField[] } } = {};
  const rowsByAccordionGroups: { [T in number]: { idx: number; fields: ServiceField[] }[] } = {};
  const rowsByAccordionGroupsNumbers: { [T in number]: number[] } = {};

  fields
    .filter(
      (f) =>
        !f.settings.DefaultSettings.NumberFieldSettings.IsCurrencyCourseField ||
        courseFieldsCodes.includes(f.code)
    )
    .map((f) => {
      const settings = templateSettings[f.code] ?? f.settings.DefaultSettings;

      if (f.type === 'hidden' || settings.IsFieldHidden) {
        return;
      }

      if (!rows[settings.RowNumber]) {
        rows[settings.RowNumber] = { idx: settings.RowNumber, fields: [] };
      }

      rows[settings.RowNumber].fields.push(f);
    });

  const rowsToDisplay = Object.values(rows).sort((a, b) => (a.idx > b.idx ? 1 : -1));

  rowsToDisplay.forEach((row) => {
    const fieldInAccordion = row.fields.find(
      (field) => field.settings.DefaultSettings.IsAccordionEnabled
    );

    if (!!fieldInAccordion) {
      rowsByAccordionGroupsNumbers[fieldInAccordion.settings.DefaultSettings.AccordionGroupNumber] =
        Array.isArray(
          rowsByAccordionGroupsNumbers[
            fieldInAccordion.settings.DefaultSettings.AccordionGroupNumber
          ]
        )
          ? [
              ...rowsByAccordionGroupsNumbers[
                fieldInAccordion.settings.DefaultSettings.AccordionGroupNumber
              ],
              row.idx,
            ]
          : [row.idx];
    }
  });

  rowsToDisplay.forEach((row) => {
    const [group] = Object.entries(rowsByAccordionGroupsNumbers).find(([_, rows]) =>
      rows.includes(row.idx)
    ) ?? [null];

    if (group === null) {
      if (!rowsByAccordionGroups['row' + row.idx]) {
        rowsByAccordionGroups['row' + row.idx] = [];
      }

      rowsByAccordionGroups['row' + row.idx].push({ ...row });
      return;
    }

    if (!rowsByAccordionGroups['accordion' + group]) {
      rowsByAccordionGroups['accordion' + group] = [];
    }

    rowsByAccordionGroups['accordion' + group].push({ ...row });
  });

  return (
    <div className="addition-service-fields-list">
      {Object.entries(rowsByAccordionGroups).map(([key, row]) => {
        if (key.includes('row')) {
          return row.map((r) => (
            <AdditionServiceFieldsRow
              key={r.idx}
              templateSettings={templateSettings}
              langID={langID}
              fields={r.fields}
              fieldValues={fieldValues}
              onChange={onChange}
              options={options}
              validation={validation}
              isFieldsDisabled={isFieldsDisabled}
              isOptionsLoading={isOptionsLoading}
              allFields={fields}
            />
          ));
        }

        return (
          <AdditionServiceFieldsAccordion
            key={key}
            rows={row}
            templateSettings={templateSettings}
            langID={langID}
            fields={[]}
            fieldValues={fieldValues}
            onChange={onChange}
            options={options}
            validation={validation}
            isFieldsDisabled={isFieldsDisabled}
            isOptionsLoading={isOptionsLoading}
            allFields={fields}
          />
        );
      })}
    </div>
  );
};

export default AdditionServiceFields;
