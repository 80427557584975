import { Service } from '@onlog-public/additional-services-types';
import { serviceToCalculateGeneratorProcessor } from '@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface';
import { ServicePriceCalculationItemsProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { v4 } from 'uuid';

/**
 * CustomsPaymentsProcessor реализует процессор генерации ЦП для вертикальных
 * таблиц. Генерирует ЦП для таможенных сборов.
 */
export class CustomsPaymentsProcessor implements serviceToCalculateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для переданной услуги.
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === 'customsPayments';
  }

  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным.
   *
   * @param service
   * @param currency
   * @param fieldValues
   * @param serviceValues
   * @param serviceState
   */
  Generate(
    service: Service,
    currency: string,
    fieldValues: { [x: string]: number },
    serviceValues: { [x: string]: number },
    _: any
  ): ServicePriceCalculationItemsProps[] {
    const values = { ...fieldValues, ...serviceValues };

    const mapVal = (values: { [x: string]: number }) => (k: string) => ({
      key: k,
      value: String(values[k]),
    });

    return [
      {
        stateID: v4(),
        currency_id: currency,
        service_id: service.id,
        values: Object.keys(values).map(mapVal(values)),
      },
    ];
  }
}
