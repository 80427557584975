import { Sbor } from '@services/requests/customsTaxService/interface';

import { CustomsPaymentsTemplateProduct } from '../types';
import { getSborCalc } from './getSborCalc';
import { moneyRounding } from './price';

interface GetTotalCalcProps {
  isExport: boolean;
  feeRowData: Calculation;
  exciseRowData: Calculation;
  vatRowData: Calculation;
  antidumpingFeeRowData: Calculation;
  exportFeeRowData: Calculation;
  title: string;
  products: CustomsPaymentsTemplateProduct[];
  productIndex: number;
}

interface Calculation {
  id: string;
  name: string;
  rate: string;
  rublesAmount: string;
}

function getTotalCalc({
  isExport,
  feeRowData,
  exciseRowData,
  antidumpingFeeRowData,
  vatRowData,
  exportFeeRowData,
  title,
  products,
  productIndex,
}: GetTotalCalcProps) {
  const antidumpingRublesSum = +antidumpingFeeRowData?.rublesAmount || 0;
  const vatRublesSum = +vatRowData?.rublesAmount || 0;
  const exciseRublesSum = +exciseRowData?.rublesAmount || 0;
  const feeRublesSum = isExport
    ? +exportFeeRowData?.rublesAmount || 0
    : +feeRowData?.rublesAmount || 0;

  const sbor = getSborCalc({ products, isTotalSbor: false });
  const currentProduct = products[productIndex];

  const isAllDutyFree = products.every(
    (product) =>
      product.selectedFee?.Value.includes('Беспошлинно') ||
      product.selectedExportFee?.Value.includes('Беспошлинно')
  );

  const isRegularExportExist = products.some(
    (product) =>
      (!!product.selectedExportFee &&
        product.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !product.selectedExportFee.ValueDetail?.ValueCount2 &&
        !product.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !product.selectedExportFee?.ValueDetail
  );

  const isRegularExport =
    (!!currentProduct.selectedExportFee &&
      currentProduct.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
      !currentProduct.selectedExportFee.ValueDetail?.ValueCount2 &&
      !currentProduct.selectedExportFee.Value?.includes('Беспошлинно')) ||
    !currentProduct.selectedExportFee?.ValueDetail;

  const currentProductSum =
    !isRegularExport && !isRegularExportExist && isExport
      ? 0
      : moneyRounding(
          (currentProduct.total?.customsSum ?? 0) +
            moneyRounding(
              antidumpingRublesSum +
                moneyRounding(vatRublesSum + moneyRounding(feeRublesSum + exciseRublesSum))
            )
        );

  const allProductsSum = products.reduce((prev, p) => {
    const { antiDumpingSum = 0, exciseSum = 0, vatSum = 0, feeSum = 0, customsSum = 0 } = p.total;

    const isDutyFree = !!p.selectedExportFee?.Value?.includes('Беспошлинно');
    const isCurrentRegularExport =
      (!!p.selectedExportFee &&
        p.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !p.selectedExportFee.ValueDetail?.ValueCount2 &&
        !p.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !p.selectedExportFee?.ValueDetail;

    if (!isCurrentRegularExport && !isDutyFree && isExport) {
      return prev;
    }

    return !isRegularExport && !isRegularExportExist && isExport
      ? 0
      : moneyRounding(
          prev +
            moneyRounding(
              antiDumpingSum +
                moneyRounding(
                  exciseSum + moneyRounding(vatSum + moneyRounding(feeSum + customsSum))
                )
            )
        );
  }, 0);

  const customsSumPercentage = 100 / ((allProductsSum || 1) / (currentProductSum || 1));
  let customsDutySum = moneyRounding(moneyRounding(sbor / 100) * customsSumPercentage);

  if (!isRegularExport && isExport) {
    const isDutyFree = !!currentProduct.selectedExportFee?.Value?.includes('Беспошлинно');

    const foundSbor = currentProduct.sborList.reduce((prev, s) => {
      if ('sbor2023' in s) {
        return prev;
      }

      const sbor = s as Sbor;

      if (
        +sbor.MINCOST <= 0 &&
        sbor.MINCOST !== '' &&
        (+sbor.MAXCOST >= 0 || sbor.MAXCOST === '') &&
        (+new Date() >= +new Date(sbor.ST_DT) || sbor.ST_DT === '1970-01-01') &&
        (+new Date() <= +new Date(sbor.STP_DT) || sbor.STP_DT === '1970-01-01')
      ) {
        return parseFloat(sbor.RATE);
      }

      return prev;
    }, 0);

    if (foundSbor && !isRegularExportExist) {
      customsDutySum = foundSbor;
    }
    if (isDutyFree) {
      customsDutySum = isRegularExportExist ? customsDutySum : 0;
    } else {
      customsDutySum = foundSbor;
    }
  }

  if (isAllDutyFree && isExport) {
    customsDutySum = 0;
  }

  const customsDutyData = {
    id: 'customs-duty',
    name: 'Таможенный сбор',
    rate: `${customsDutySum} RUB`,
    rublesAmount: customsDutySum.toString(),
  };

  const totalRowData = {
    id: 'total',
    name: title,
    rate: '0%',
    rublesAmount: moneyRounding(
      feeRublesSum +
        moneyRounding(
          vatRublesSum +
            moneyRounding(exciseRublesSum + moneyRounding(antidumpingRublesSum + customsDutySum))
        )
    ).toString(),
  };

  const resultFee = isExport ? exportFeeRowData : feeRowData;

  const result = [
    resultFee,
    antidumpingFeeRowData,
    exciseRowData,
    vatRowData,
    customsDutyData,
    totalRowData,
  ].filter((row) => Boolean(row));

  return result;
}

export { getTotalCalc };
