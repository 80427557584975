import { SelectOption } from '@pages/AdditionalServices/containers/services/optionsGenerator/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {Service, ServiceField} from "@onlog-public/additional-services-types";
import {VerticalTableTemplate} from "@onlog-public/additional-services-types/src/templates/VerticalTableTemplate";
import AdditionServiceField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";

export interface ServicesRowProps {
  langID: string;
  rowID: string;
  rowNumber: number;
  isFirstRow: boolean;
  totalRowsQty: number;
  isOrderDisplay: boolean;
  isOptionsLoading: boolean;
  settings: VerticalTableTemplate;
  costColumnField: ServiceField;
  costColumnFieldValue: number;
  onChangeCostColumnField: (value: number) => void;
  targetCurrency: CurrencyData;
  columns: Service[];
  course: string;
  serviceCalculations: ServicesServicePriceCalculationMutationProps[];
  allOptions: { [T in string]?: SelectOption[] };
  fieldValues: { [T in string]: number };
}

const ServicesRow: FC<ServicesRowProps> = (props) => {
  const {
    langID,
    rowID,
    rowNumber,
    isFirstRow,
    totalRowsQty,
    costColumnField,
    costColumnFieldValue,
    onChangeCostColumnField,
    settings,
    isOrderDisplay,
    isOptionsLoading,
    columns,
    serviceCalculations,
    targetCurrency,
    course,
    allOptions,
    fieldValues,
  } = props;
  const { t } = useTranslation();

  const rowNumText = [settings.PositionPrefix, String(rowNumber), settings.PositionSuffix]
    .filter((v) => v.length > 0)
    .map((v) => t(v))
    .join('');

  let totalCost = 0;
  let totalTax = 0;
  let totalSum = 0;

  columns.map((column) => {
    const settings = column.settings.TemplateSettings.ServiceInVerticalTable;

    const isDivideBetweenServices = settings.IsNeedCalculateSingleValue;

    const calculation = serviceCalculations.find(
      (c) => c.serviceId === column.id && (c.stateID === rowID || isDivideBetweenServices)
    );

    if (!calculation) {
      return;
    }

    totalCost +=
      calculation.result.result.priceInTargetCurrency /
      (isDivideBetweenServices ? totalRowsQty : 1);
    totalTax += calculation.result.result.taxInTargetCurrency;
    totalSum +=
      calculation.result.result.fullPriceInTargetCurrency /
      (isDivideBetweenServices ? totalRowsQty : 1);
  });

  const currency = targetCurrency.glyph.length ? targetCurrency.glyph : ` ` + targetCurrency.code;

  return (
    <>
      <div className="with-left-padding no-left-border">{rowNumText}</div>
      <div className="addition-service-fields-container">
        <div className="addition-service-fields-list">
          <div className="addition-service-fields-row">
            <AdditionServiceField
              field={costColumnField}
              value={costColumnFieldValue}
              isOptionsLoading={isOptionsLoading}
              isFieldsDisabled={isOrderDisplay}
              langID={langID}
              onChange={(v) => onChangeCostColumnField(v)}
              options={[]}
              allOptions={allOptions}
              allFields={[]}
              fieldValues={fieldValues}
            />
          </div>
        </div>
      </div>
      {!settings.IsCourseColumnHidden && <div className="center">{course}</div>}
      {columns.map((column) => {
        const settings = column.settings.TemplateSettings.ServiceInVerticalTable;
        if (settings.IsNeedCalculateSingleValue) {
          if (!isFirstRow) {
            return;
          }

          const calculation = serviceCalculations.find((c) => c.serviceId === column.id);
          return (
            <div key={column.id} className="center" style={{ gridRow: `span ${totalRowsQty}` }}>
              {(calculation?.result.result.priceInTargetCurrency ?? 0).toLocaleString()}
              {currency}
            </div>
          );
        }

        const calculation = serviceCalculations.find(
          (c) => c.serviceId === column.id && c.stateID === rowID
        );

        return (
          <div key={column.id} className="center">
            {(calculation?.result.result.priceInTargetCurrency ?? 0).toLocaleString()}
            {currency}
          </div>
        );
      })}
      <div className="center">
        {totalCost.toLocaleString()}
        {currency}
      </div>
      <div className="center">
        {totalTax.toLocaleString()}
        {currency}
      </div>
      <div className="center">
        {totalSum.toLocaleString()}
        {currency}
      </div>
    </>
  );
};

export default ServicesRow;
