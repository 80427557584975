import { ServiceField } from '@onlog-public/additional-services-types';

import { SelectOption } from '../services/optionsGenerator/types';

/**
 * calculateFieldValues выполняет расчет значений полей по формулам,
 * если они заданы для какого либо поля.
 * Если поле само является источником изменений, то вычисление значения
 * для него пропускается.
 *
 * @param fields
 * @param values
 * @param source
 * @param directoryValues
 */
export const calculateFieldValues = (
  fields: ServiceField[],
  values: { [T in string]: number },
  fieldOptions: { [T in string]: SelectOption[] },
  source?: string,
  directoryValues: { [T in string]: number } = {}
): { [T in string]: number } => {
  const result = { ...values };

  let iframe = null;
  for (let i = window.frames.length - 1; i >= 0; i--) {
    if (window.frames[i].frameElement.id == 'a-service-iframe') {
      iframe = window.frames[i];
      break;
    }
  }

  if (!iframe) {
    return result;
  }

  fields.map((field) => {
    if (field.code === source || !field.settings.IsFieldValueShouldCalculate) {
      return;
    }

    Object.keys(values).map((key) => {
      iframe[key] = values[key];
    });
    Object.keys(directoryValues).map((key) => {
      iframe[key] = directoryValues[key];
    });

    result[field.code] = iframe.eval(field.settings.FieldCalculationFormula);

    if (field.type !== 'number') {
      result[field.code] = field.value;
    }

    const settings = field.settings.DefaultSettings.NumberFieldSettings;
    if (settings.LimitMaxValue !== undefined && result[field.code] > settings.LimitMaxValue) {
      result[field.code] = settings.LimitMaxValue;
    }

    if (settings.LimitMinValue !== undefined && result[field.code] < settings.LimitMinValue) {
      result[field.code] = settings.LimitMinValue;
    }
  });

  fields
    .filter((f) => f.settings.DefaultSettings.NumberFieldSettings.IsCurrencyCourseField)
    .map((field) => {
      if (
        field.type === 'number' &&
        field.settings.DefaultSettings.NumberFieldSettings.IsCurrencyCourseField &&
        fieldOptions[field.settings.DefaultSettings.NumberFieldSettings.CurrencyField] &&
        fieldOptions[field.settings.DefaultSettings.NumberFieldSettings.CurrencyField].length > 0
      ) {
        const sourceField = fields.find((f) => f.code === source);
        if (!!source && !!sourceField && sourceField.type !== 'currency') {
          result[field.code] = values[field.code];
        } else {
          const foundCurrency = fieldOptions[
            field.settings.DefaultSettings.NumberFieldSettings.CurrencyField
          ].find(
            (option) =>
              option.value ===
              values[field.settings.DefaultSettings.NumberFieldSettings.CurrencyField]
          );

          if (!!foundCurrency) {
            if (
              !!sourceField &&
              sourceField.code === field.settings.DefaultSettings.NumberFieldSettings.CurrencyField
            ) {
              result[field.code] = foundCurrency.origin.course / foundCurrency.origin.nominal;
              return;
            }

            result[field.code] =
              values[field.code] !==
                values[field.settings.DefaultSettings.NumberFieldSettings.CurrencyField] &&
              values[field.code] !== 0
                ? values[field.code]
                : foundCurrency.origin.course / foundCurrency.origin.nominal;
          }
        }
      }
    });

  return result;
};
