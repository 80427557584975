import { Service, ServiceField } from '@onlog-public/additional-services-types';
import React, { WheelEvent } from 'react';

import { CHECK_EXCLUDE_KEYS, VALIDATE_CHECKS } from '../consts';
import { CustomsPaymentsTemplateProduct } from '../types';

export const checkErrorExisting = (keys: string[], errors: string[]) => {
  return keys.some((key) => errors.includes(key));
};

export const validateCustomsPaymentProducts = (
  products: CustomsPaymentsTemplateProduct[],
  filterErrorKeys: string[],
  service: Service,
  fields: ServiceField[],
  fieldValues: { [T in string]: number }
) => {
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const borderDeliverySumField = fields.find((f) => f.code === settings.BorderDeliverySumField);

  const isSingleProduct = products.length === 1;
  const borderDeliverySum = fieldValues[borderDeliverySumField.code];

  return products.map((product) => {
    const isSpecialFieldsActive = typeof product.selectedExcise?.Prim === 'string';
    const isExciseCountFieldActive =
      !!product.selectedExcise &&
      product.selectedExcise?.Measure?.Razm !== product.selectedFee?.Measure?.Razm;

	  const isProductsCountFieldVisible = [
		product.selectedFee?.ValueDetail?.ValueUnit,
		product.selectedFee?.ValueDetail?.ValueUnit2,
		product.selectedFee?.ValueDetail?.ValueUnit3,
		product.selectedFee?.ValueDetailAdd?.ValueUnit,
		product.selectedExportFee?.ValueDetail?.ValueUnit,
		product.selectedExportFee?.ValueDetail?.ValueUnit2,
	  ]
		.filter(Boolean)
		.reduce((_, unit) => !unit.includes('%'), false);

    const isBaseCountVisible =
      !product.selectedExportFee ||
      (!!product.selectedExportFee && !product.selectedExportFee?.Value.includes('Беспошлинно'));

    return Object.entries(product)
      .filter(([key]) => {
        if (
          !isSpecialFieldsActive &&
          ['maximumRetailPackagePrice', 'packagesCount'].includes(key)
        ) {
          return false;
        }
        if (!isExciseCountFieldActive && ['exciseCount'].includes(key)) {
          return false;
        }
        if ((isSingleProduct || borderDeliverySum === 0) && ['weight'].includes(key)) {
          return false;
        }
        if (
          !isBaseCountVisible ||
          (!isProductsCountFieldVisible && ['productsCount'].includes(key))
        ) {
          return false;
        }

        return true;
      })
      .map(([key, value]) => {
        if (CHECK_EXCLUDE_KEYS.includes(key)) {
          return null;
        }

        const check = VALIDATE_CHECKS[key];

        if (check instanceof RegExp) {
          return !check.test(value) && key;
        } else if (typeof check === 'boolean') {
          return check !== value && key;
        }

        return null;
      })
      .filter(Boolean)
      .filter((errorKey) => filterErrorKeys.length === 0 || filterErrorKeys.includes(errorKey));
  });
};

export const mouseWheelInputChangePreventHandler = (e: any) => {
  e.target.blur();
  e.stopPropagation();

  setTimeout(() => {
    e.target.focus();
  }, 0);
};
