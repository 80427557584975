import { Sbor, Sbor2023 } from '@services/requests/customsTaxService/interface';

import { CustomsPaymentsTemplateProduct } from '../types';

interface GetSborCalcProps {
  products: CustomsPaymentsTemplateProduct[];
}

export function getSborCalc({ products }: GetSborCalcProps) {
  const prodcutCustomsSum = products.map((product) => {
    const customsSum = product.total.customsSum ?? 0;
    const sborList = product.sborList;

    const fixedSbor = sborList.find((s) => 'sbor2023' in s) as Sbor2023;

    if (fixedSbor) {
      return fixedSbor.sbor2023;
    }

    const sbor = sborList.reduce((prev, s) => {
      if ('sbor2023' in s) {
        return prev;
      }

      const sbor = s as Sbor;

      if (
        +sbor.MINCOST <= customsSum &&
        sbor.MINCOST !== '' &&
        (+sbor.MAXCOST >= customsSum || sbor.MAXCOST === '') &&
        (+new Date() >= +new Date(sbor.ST_DT) || sbor.ST_DT === '1970-01-01') &&
        (+new Date() <= +new Date(sbor.STP_DT) || sbor.STP_DT === '1970-01-01')
      ) {
        return parseFloat(sbor.RATE);
      }

      return prev;
    }, 0);

    return sbor;
  });

  const totalCustomsSum = products.reduce(
    (prev, product) => prev + (product.total.customsSum ?? 0),
    0
  );

  const sborList =
    (products?.[0]?.sborList.filter((s) => {
      if ('sbor2023' in s) {
        return false;
      }

      return true;
    }) as Sbor[]) ?? [];

  const foundTotalSbor = sborList.reduce((prev, s) => {
    if (
      +s.MINCOST <= totalCustomsSum &&
      s.MINCOST !== '' &&
      (+s.MAXCOST >= totalCustomsSum || s.MAXCOST === '') &&
      (+new Date() >= +new Date(s.ST_DT) || s.ST_DT === '1970-01-01') &&
      (+new Date() <= +new Date(s.STP_DT) || s.STP_DT === '1970-01-01')
    ) {
      return parseFloat(s.RATE);
    }

    return prev;
  }, 0);

  const maxSbor = Math.max(foundTotalSbor, ...prodcutCustomsSum);

  return maxSbor;
}
