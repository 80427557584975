import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material';

const OfferDetailStyledContainer = styled('section')`
  padding: 30px;
  gap: 24px;
  display: flex;
  flex-flow: column;
  width: 100%;

  .heading {
    flex: 0 0 100%;

    @media (max-width: ${breakpoints.values.laptop}px) {
      font-size: 22px;
    }
  }

  .title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .altaTaksaTable {
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    background-color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .altaTaksaTable__th {
    background-color: #f7f7f7;
  }

  .altaTaksaTable__td,
  .altaTaksaTable__th {
    padding: 5px;
    border: 1px solid #ccc;
  }

  .altaTaksaTable__td--value,
  .altaTaksaTable__th--value {
    text-align: right;
  }

  .altaTaksaTable__th--inline {
    text-align: left;
  }

  .product-help-detailing {
    display: flex;
    flex-direction: column;
    margin-top: 46px;

    .title {
      margin-bottom: 30px;
    }
  }

  .product-help-detailing-item {
    padding: 30px;
    background-color: #edf3fd;
    border-radius: 18px;
    margin-bottom: 15px;

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.46px;
      color: #000;
    }

    &__text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23.46px;
      color: #000;
    }
  }

  .offer-product {
    margin-bottom: 24px;

    .title {
      margin-top: 50px;

      &__cargo-name {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .offer-group-header {
      &__container-rental {
        grid-template-columns: 40px 1fr auto;
        gap: 15px;
      }
    }
  }

  .additional-service-table {
    .MuiDataGrid-root {
      overflow-x: hidden;
      border-radius: 0 0 18px 18px !important;
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]} !important;
    }
  }

  .MuiBox-root ~ .unloading-loading {
    border-top: 0;
  }

  .MuiBox-root {
    .MuiDataGrid-virtualScrollerContent--overflowed {
      .MuiDataGrid-row--lastVisible {
        .MuiDataGrid-cell {
          border-bottom-color: ${({ theme }) => theme.palette.grey[200]};
        }
      }
    }
  }

  .unloading-loading {
    overflow-y: auto;
    display: grid;
    grid-template-columns: minMax(285px, 1fr) 7fr;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};

    &.--border-bottom {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }

    &__section {
      padding: 10px 20px;
      border-top: 0;
      display: flex;
      align-items: center;

      &:first-of-type {
        border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
      clear: both;
    }
  }

  .add-info {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.grey[800]};
  }

  .table-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: ${breakpoints.values.md}px) {
      gap: 20px;
    }
  }

  .offer-footer-info {
    &__heading {
      display: flex;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.palette.grey[100]};
      padding: 20px;

      @media (max-width: ${breakpoints.values.sm}px) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.values.sm}px) {
    padding: 30px 15px;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: ${breakpoints.values.md}px) {
      gap: 20px;
    }
  }

  .bottom-caption {
    margin-top: 20px;
    color: ${({ theme }) => theme.palette.grey[300]};
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  .summary-wrapper {
    border-radius: 18px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  @media print {
    padding: 20px;
  }

  .logo {
    width: 100%;
    margin-bottom: 30px;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    color: ${palette.light.common.black};

    &__until-date,
    &__order {
      font-weight: 400 !important;
      color: #000 !important;
      font-size: 16px !important;
      font-style: normal !important;
      line-height: normal !important;
    }
  }

  .product-list-product {
    opacity: 1;
    transition: 1s opacity;

    &.rendering {
      opacity: 0;
    }
  }

  .offer-group-header {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 45px;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: ${palette.light.blue[300]};
    border-radius: 18px 18px 0 0;

    &__service {
      grid-template-columns: 1fr auto;
    }

    @media (max-width: ${breakpoints.values.md}px) {
      grid-gap: 15px;
    }

    &__right {
      color: black;
      font-size: 16px;
      white-space: nowrap;
    }

    .MuiTypography-subtitle1 {
      @media (max-width: ${breakpoints.values.sm}px) {
        font-size: 14px;
        text-align: center;
      }
    }

    .price {
      white-space: nowrap;
    }

    &__title {
      display: flex;
      gap: 20px;
      align-items: center;

      @media (max-width: ${breakpoints.values.sm}px) {
        flex-direction: column;
      }
    }
  }

  .offer-accordion-group {
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-radius: 18px;
    overflow: hidden;

    .MuiDataGrid-root {
      border: none;
    }

    &__body > div {
      &:last-child {
        .MuiDataGrid-row--lastVisible {
          .MuiDataGrid-cell {
            border-bottom: 0;
          }
        }

        .unloading-loading {
          &__section {
            border-bottom: 0;
          }
        }
      }
    }

    footer {
      padding: 0;
    }
  }

  .transport-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: ${palette.light.common.white};
    filter: drop-shadow(0px 1px 3px rgba(47, 59, 69, 0.3));
    border-radius: 50%;

    svg {
      width: 26px;
      height: 26px;

      path {
        fill: ${palette.light.green[300]};
      }
    }
  }
`;

export default OfferDetailStyledContainer;
