import { v4 } from 'uuid';

import { CustomsPaymentsTemplateProduct } from './types';

export class DefaultCustomsPaymentsProduct implements CustomsPaymentsTemplateProduct {
  productId = v4();
  isCalculated = false;
  code = '';
  codeDescription = '';
  contractCurrencyCost = '';
  country = '';
  weight = '0';
  originCertificateExisting = true;
  exciseCount = '0';
  productsCount = '0';
  maximumRetailPackagePrice = '0';
  packagesCount = '0';
  total = {};
  selectedFee = null;
  selectedAntidumpingFee = null;
  selectedVat = null;
  selectedExcise = null;
  selectedExportFee = null;
  notes = [];
  invalidFields = [];
  sborList = [];
  sborListImport = [];
}
