import {getFilesIds} from '@components/pages/Products/components/RouteProduct/useGetFilesFromRoute';
import useBaseDataContext from '@context/useBaseDataContext';
import {getLocalizationById, parseUrl} from '@context/useMyRouting/helpers';
import {LinearProgress} from '@material-ui/core';
import Button from '@mui/material/Button';
import OfferDetail from '@pages/OfferDetail';
import {PDFExport} from '@progress/kendo-react-pdf';
import {filesService} from '@services/requests/filesService';
import {FileData} from '@services/requests/filesService/interface';
import {orderService} from '@services/requests/orderService';
import {Order} from '@services/requests/orderService/interface';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import getTaxNames, {TaxNameProps} from '../../helpers/getTaxNames';
import Container from './StyledComponents';

const loadOrder = async (id: string, token: string) => {
  return await orderService(token).Load('order', id);
};

const OrderPage = () => {
  const {useBaseData} = useBaseDataContext();
  const [baseData] = useBaseData().useState();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const pdfExportComponent = React.useRef(null);
  const button = React.useRef(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [needPrint, setNeedPrint] = useState<boolean>(false);
  const [files, setFiles] = useState<FileData[]>([]);
  const [taxNames, setTaxNames] = useState<TaxNameProps[]>([]);
  const langCode = getLocalizationById(order?.language_id)?.countryCode;
  const {t} = useTranslation('OfferContainers', {lng: langCode});
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setIsSaved(true);
    }
  };

  useEffect(() => {
    if (!needPrint) {
      return;
    }

    let interval;

    if (!isSaved) {
      interval = setInterval(() => {
        exportPDFWithComponent();
      }, 6000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isSaved, needPrint]);

  useLayoutEffect(() => {
    if (!baseData) {
      return;
    }
    const data = parseUrl(window.location.href, window.location.search);
    const {id = '', token = '', needPrint = '0', selectedProduct} = data;

    setSelectedProducts(selectedProduct);
    setNeedPrint(needPrint === '1');
    loadOrder(id, token).then((order) => {
      const filesToLoad = getFilesIds(order.products, baseData);
      filesService().LoadFilesById(filesToLoad).then(setFiles);
      getTaxNames('1').then(setTaxNames);
      setOrder(order);
    });
  }, [baseData]);

  if (!baseData || order === undefined) {
    return (
      <Container>
        <LinearProgress/>
      </Container>
    );
  }

  const productsToDisplay = selectedProducts.length > 0
    ? selectedProducts
    : [...order.products, ...(order?.pre_order_products ?? [])]
      .map(p => p.id)

  return (
    <PDFExport
      ref={pdfExportComponent}
      paperSize="A4"
      scale={0.4}
      keepTogether={".kendo-pdf--prevent-split"}
      fileName={`Report for ${new Date().getFullYear()}`}
    >
      <Container className={"is-pdf-export"}>
        <Button style={{display: 'none'}} ref={button} onClick={exportPDFWithComponent}>
          Print
        </Button>
        <OfferDetail
          order={order}
          taxNames={taxNames}
          files={files}
          baseData={baseData}
          selectedProducts={productsToDisplay}
          title={t('OfferContainers:OfferDetailTitle')}
        />
      </Container>
    </PDFExport>
  );
};

export default OrderPage;
