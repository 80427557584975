import { Sbor, Sbor2023 } from '@services/requests/customsTaxService/interface';

import { CustomsPaymentsTemplateProduct } from '../types';

interface GetSborCalcProps {
  products: CustomsPaymentsTemplateProduct[];
  isTotalSbor: boolean;
}

export function getSborCalc({ products, isTotalSbor }: GetSborCalcProps) {
  const isExport = !!products?.[0] && !!products[0].selectedExportFee;

  const regularProducts = products.map(
    (product) =>
      (!!product.selectedExportFee &&
        product.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !product.selectedExportFee.ValueDetail?.ValueCount2 &&
        !product.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !!product.selectedExportFee?.Value?.includes('Беспошлинно') ||
      !product.selectedExportFee?.ValueDetail
  );

  const isRegularExportExist = products.some(
    (product) =>
      (!!product.selectedExportFee &&
        product.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !product.selectedExportFee.ValueDetail?.ValueCount2 &&
        !product.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !product.selectedExportFee?.ValueDetail
  );

  const productCustomsSum = products.map((product) => {
    const isRegularExport =
      (!!product.selectedExportFee &&
        product.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !product.selectedExportFee.ValueDetail?.ValueCount2 &&
        !product.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !product.selectedExportFee?.ValueDetail;
    const isDutyFree = !!product.selectedExportFee?.Value?.includes('Беспошлинно');

    const customsSum = !isRegularExport ? 0 : product.total.customsSum ?? 0;
    const sborList = isRegularExport || isDutyFree ? product.sborListImport : product.sborList;

    const fixedSbor = sborList.find((s) => 'sbor2023' in s) as Sbor2023;

    if (!isRegularExportExist && isDutyFree && isExport) {
      return 0;
    }

    if (fixedSbor) {
      return fixedSbor.sbor2023;
    }

    const sbor = sborList.reduce((prev, s) => {
      if ('sbor2023' in s) {
        return prev;
      }

      const sbor = s as Sbor;

      if (
        +sbor.MINCOST <= customsSum &&
        sbor.MINCOST !== '' &&
        (+sbor.MAXCOST >= customsSum || sbor.MAXCOST === '') &&
        (+new Date() >= +new Date(sbor.ST_DT) || sbor.ST_DT === '1970-01-01') &&
        (+new Date() <= +new Date(sbor.STP_DT) || sbor.STP_DT === '1970-01-01')
      ) {
        return parseFloat(sbor.RATE);
      }

      return prev;
    }, 0);

    return sbor;
  });

  const totalCustomsSum = products.reduce((prev, product) => {
    const isRegularExport =
      (!!product.selectedExportFee &&
        product.selectedExportFee.ValueDetail?.ValueUnit === '%' &&
        !product.selectedExportFee.ValueDetail?.ValueCount2 &&
        !product.selectedExportFee.Value?.includes('Беспошлинно')) ||
      !product.selectedExportFee?.ValueDetail;

    return prev + (!isRegularExport && isExport ? 0 : product.total.customsSum ?? 0);
  }, 0);

  const sborList =
    (products?.[0]?.sborListImport.filter((s) => {
      if ('sbor2023' in s) {
        return false;
      }

      return true;
    }) as Sbor[]) ?? [];

  const foundTotalSbor = sborList.reduce((prev, s) => {
    if (!isRegularExportExist && isExport) {
      return 0;
    }

    if (
      +s.MINCOST <= totalCustomsSum &&
      s.MINCOST !== '' &&
      (+s.MAXCOST >= totalCustomsSum || s.MAXCOST === '') &&
      (+new Date() >= +new Date(s.ST_DT) || s.ST_DT === '1970-01-01') &&
      (+new Date() <= +new Date(s.STP_DT) || s.STP_DT === '1970-01-01')
    ) {
      return parseFloat(s.RATE);
    }

    return prev;
  }, 0);

  const specialProductsCustomsSum = productCustomsSum.reduce((prev, item, index) => {
    if (regularProducts[index] === false) {
      return prev + item;
    }

    return prev;
  }, 0);

  let maxSbor = Math.max(
    foundTotalSbor,
    ...productCustomsSum.filter((_, index) => regularProducts[index] === true)
  );

  if (!isExport) {
    return Math.max(foundTotalSbor, ...productCustomsSum);
  }

  const isAllDutyFree = products.every(
    (product) =>
      product.selectedFee?.Value.includes('Беспошлинно') ||
      product.selectedExportFee?.Value.includes('Беспошлинно')
  );
  if (isAllDutyFree) {
    maxSbor = 0;
  }

  if (isTotalSbor) {
    const maxSbor = Math.max(
      foundTotalSbor,
      ...productCustomsSum.filter((_, index) => regularProducts[index] === true)
    );

    return maxSbor + specialProductsCustomsSum;
  }

  return maxSbor;
}
