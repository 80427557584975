import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

const Container = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @font-face {
    font-family: 'Manrope';
    font-weight: normal;
    font-style: normal;
    src: local('Manrope Regular'), url('/fonts/Manrope-Regular.ttf') format('truetype');
  }
  font-family: 'Manrope', sans-serif;

  & .overall-wrap {
    flex-direction: row !important;
    justify-content: space-between !important;
    gap: 60px !important;

    @media (max-width: ${breakpoints.values.xsm}px) {
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 150px !important;
    }
  }

  & .product-main-wrap {
    @media (max-width: ${breakpoints.values.xsm}px) {
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 60px !important;
    }
  }

  & .result-wrap {
    @media (max-width: ${breakpoints.values.xsm}px) {
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 60px !important;
    }
  }

  & .a-service-basket--summary {
    grid-template-columns: 1fr min-content !important;

    &-tax {
      order: unset !important;
    }
  }

  & .result-item__table {
    width: 100% !important;
  }

  & .result-item {
    width: 100% !important;
  }
`;

export default Container;
