import {
  makeOptionsForLocationChanges,
  optionsState$,
} from '@pages/AdditionalServices/containers/additionServicesOptionsContext';
import { serviceCalculationBus$, serviceContext$ } from '@pages/AdditionalServices/containers/bus';
import { calculateFieldValues } from '@pages/AdditionalServices/containers/methods/calculateFieldValues';
import { clone } from '@pages/AdditionalServices/containers/methods/clone';

/**
 * setFieldValue выполняет изменение значения поля в стейте.
 * Если значение поля не изменилось, то скипает обработку.
 * Выполняет расчет значений для полей с формулами.
 *
 * @param field
 * @param value
 * @param isFieldChange
 */
export const setFieldValue = (field: string, value: number, isFieldChange: boolean = false) => {
  const currentState = serviceContext$.getValue();
  const currentOptionsState = optionsState$.getValue();
  if (currentState.FieldValues[field] === value) {
    return;
  }

  const state = clone(currentState);
  state.FieldValues[field] = value;

  // Пересчитываем значения полей директорий
  state.FieldValues = calculateFieldValues(
    state.FieldsToDisplay,
    state.FieldValues,
    currentOptionsState.FieldOptions,
    field
  );

  // Пересчитываем значения полей для вариантов, если они есть.
  const serviceValues = { ...state.VariantFieldValues };
  state.Variants.map((v) => {
    serviceValues[v.id] = calculateFieldValues(
      state.VariantFields[v.id],
      { ...serviceValues[v.id] },
      currentOptionsState.FieldOptions,
      undefined,
      state.FieldValues
    );
  });
  state.VariantFieldValues = serviceValues;
  state.IsServicesCalculating = state.IsNeedCalculateServices;

  serviceContext$.next(state);
  serviceCalculationBus$.next({ isFieldChanges: !isFieldChange, isNeedChooseVariant: false });

  // Отправляем дополнительную загрузку опций для локаций.
  // В этом поле используется поиск с подсказкой, поэтому опций изначально нет.
  // Этот метод их загрузит. После этого опции станут доступны для экспорта в
  // продукт заказа.
  const fieldData = state.FieldsToDisplay.find((f) => f.code === field);
  if (!!fieldData) {
    makeOptionsForLocationChanges(fieldData, value);
  }
};
