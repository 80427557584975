import getLocalizationByArgs from '@helpers/getLocalizationByArgs';
import { CircularProgress } from '@material-ui/core';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SelectionButton from '@pages/AdditionalServices/components/Service/SelectionButton';
import ServiceFieldsList from '@pages/AdditionalServices/components/Service/ServiceFieldsList';
import { ServiceProps } from '@pages/AdditionalServices/components/Service/types';
import ServicesRow from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/ServicesRow';
import ServiceSummaryRow from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/ServiceSummaryRow';
import TableHeader from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/TableHeader';
import TitleRow from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/TitleRow';
import TotalPriceColumn from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/TotalPriceColumn';
import { VerticalTableTemplateStore } from '@pages/AdditionalServices/components/Service/VerticalTableTemplate/types';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import clsx from 'clsx';
import { round } from 'lodash';
import React, { FC } from 'react';
import { v4 } from 'uuid';

const VerticalTableTemplate: FC<ServiceProps> = (props) => {
  const {
    langID,
    currencyID,
    variant,
    service,
    globalFields,
    servicesFields,
    serviceTemplateStore,
    isOrderDisplay,
    isServicesCalculating,
    serviceCalculations,
    serviceDataCache,
    options,
    globalFieldValues,
    servicesFieldValues,
    onChangeGlobalFieldValue,
    onChangeServiceFieldValue,
    onChangeServiceTemplateStore,
  } = props;

  const targetCurrency: CurrencyData | undefined = serviceDataCache.currency.cache[currencyID];
  const store: VerticalTableTemplateStore = serviceTemplateStore[service.id];
  const fields = [...servicesFields, ...globalFields];
  const settings = service.settings.TemplateSettings.VerticalTable;
  const costField = fields.find((f) => f.code === settings.CostColumnField);
  const rowsField = fields.find((f) => f.code === settings.RowQuantityField);
  const courseField = fields.find((f) => f.code === settings.CourseColumnCurrencyField);

  if (!costField || !rowsField || !targetCurrency) {
    return null;
  }

  // handleChangeRowValue реализует обработку изменения количества строк
  const handleChangeRowValue = (value: number) => {
    const rowsQty = Object.values(store.rows).length;
    if (rowsQty === value) {
      return;
    }

    const newStore = { ...store };
    if (rowsQty < value) {
      for (let i = 0; i < value - rowsQty; i++) {
        const rowID = v4();
        newStore.rows[rowID] = costField.value ?? 0;
        newStore.rowsDirection[rowID] = Object.values(newStore.rowsDirection).length;
      }
    } else {
      const rows = Object.keys(newStore.rows).sort((a, b) => {
        return newStore.rowsDirection[a] < newStore.rowsDirection[b] ? 1 : -1;
      });

      for (let i = 0; i < rowsQty - value; i++) {
        const rowID = rows[i];
        const { [rowID]: _, ...resultRows } = newStore.rows;
        const { [rowID]: __, ...resultRowsDirection } = newStore.rowsDirection;

        newStore.rows = resultRows;
        newStore.rowsDirection = resultRowsDirection;
      }
    }

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });
  };

  // handleChangeGlobalFieldValue содержит переопределенный обработчик изменения значения
  // поля количества строк
  const handleChangeGlobalFieldValue = (field: string, value: number) => {
    if (field === costField.code) {
      return;
    }

    if (field !== rowsField.code) {
      return onChangeGlobalFieldValue(field, value);
    }

    const correctValue = value < 0 ? 0 : value;
    handleChangeRowValue(correctValue);
    onChangeGlobalFieldValue(field, correctValue);
  };

  // handleChangeServiceFieldValue содержит переопределенный обработчик изменения значения
  // поля количества строк для полей услуг.
  const handleChangeServiceFieldValue = (field: string, value: number) => {
    if (field === costField.code) {
      return;
    }

    if (field !== rowsField.code) {
      return onChangeServiceFieldValue(field, value);
    }

    const correctValue = value < 0 ? 0 : value;
    handleChangeRowValue(correctValue);
    onChangeServiceFieldValue(field, correctValue);
  };

  // handleChangeCostFieldValue обрабатывает изменение значения поля колонки стоимость.
  // Сохраняет изменение в стор, а так же вычисляет общую стоимость и записывает ее
  // в поле.
  const handleChangeCostFieldValue = (rowID: string, value: number) => {
    const newStore: VerticalTableTemplateStore = {
      ...store,
      rows: { ...store.rows, [rowID]: value },
    };

    onChangeServiceTemplateStore(variant.id, service.id, newStore);
    const total = Object.values(newStore.rows).reduce((v, c) => v + c, 0);

    const globalSumField = globalFields.find((f) => f.code === settings.CostColumnSumField);
    if (!!globalSumField) {
      return handleChangeGlobalFieldValue(settings.CostColumnSumField, total);
    }

    handleChangeServiceFieldValue(settings.CostColumnSumField, total);
  };

  const serviceName = getLocalizationByArgs(langID, service.name, service.localized_names);
  const serviceDescription = getLocalizationByArgs(
    langID,
    service.description,
    service.localized_descriptions
  );
  const columns =
    service?.additionServices
      ?.filter((s) => s.template === 'serviceInVerticalTable')
      .filter((s) => s.is_active && !s.is_broken)
      .sort((a, b) =>
        a.settings.TemplateSettings.ServiceInVerticalTable.PositionInList >
        b.settings.TemplateSettings.ServiceInVerticalTable.PositionInList
          ? 1
          : -1
      ) ?? [];

  const columnsToDisplay = columns.slice(0, 5);
  const columnsQty = columnsToDisplay.length + (settings.IsCourseColumnHidden ? 0 : 1);

  let isNeedDisplayTitleRow = settings.IsNeedShowTitleRow;
  columnsToDisplay.map((col) => {
    const settings = col.settings.TemplateSettings.ServiceInVerticalTable;
    isNeedDisplayTitleRow =
      isNeedDisplayTitleRow ||
      Object.values(settings.DuplicateFields.Fields).length > 0 ||
      settings.TitleRowText.length > 0 ||
      col.fields.filter((f) => f.type !== 'hidden' && !f.settings.DefaultSettings.IsFieldHidden)
        .length > 0;
  });

  const rows = Object.keys(store.rows).sort((a, b) =>
    store.rowsDirection[a] > store.rowsDirection[b] ? 1 : -1
  );

  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  let course = '-';
  if (!!courseField) {
    course = fieldValues[courseField.code].toLocaleString();
    if (courseField.type === 'currency') {
      course = '-';
      const sourceCurrency = options[courseField.code]?.find(
        (o) => o.value === fieldValues[courseField.code]
      );

      if (!!sourceCurrency && !!targetCurrency) {
        const convertedCourse =
          ((sourceCurrency.origin.course / sourceCurrency.origin.nominal) *
            targetCurrency.nominal) /
          targetCurrency.course;
        course = round(convertedCourse, 2).toLocaleString();
      }
    }
  }

  const totalCostColumn = Object.values(store.rows).reduce((v, c) => v + c, 0);
  const isNeedShowButton =
    !isOrderDisplay && service.settings.TemplateSettings.VerticalTable.IsNeedShowAddToBasketButton;

  return (
    <div className="a-service-template-verticalTable kendo-pdf--prevent-split">
      <div className="a-service-template-verticalTable-title">
        <div>
          {serviceDescription.length > 0 && (
            <Tooltip
              placement="top"
              title={
                <div
                  dangerouslySetInnerHTML={{ __html: serviceDescription }}
                  className="a-html-content"
                />
              }
            >
              <IconButton sx={{ width: 16, height: 16, backgroundColor: '#EFF3FB' }}>
                <QuestionMarkIcon sx={{ fontSize: 8, color: '#707070' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div>
          <Tooltip
            placement="top"
            title={
              serviceDescription.length > 0 && (
                <div
                  dangerouslySetInnerHTML={{ __html: serviceDescription }}
                  className="a-html-content"
                />
              )
            }
          >
            <span>{serviceName}</span>
          </Tooltip>
        </div>
      </div>
      <div className="a-service-template-verticalTable-fields">
        <ServiceFieldsList
          {...props}
          onChangeGlobalFieldValue={handleChangeGlobalFieldValue}
          onChangeServiceFieldValue={handleChangeServiceFieldValue}
        />
        <div className="progress-container">
          {isServicesCalculating && <CircularProgress color="inherit" size={20} />}
        </div>
      </div>
      <div className="a-service-template-cargoDeclaration-cost">
        {settings.IsNeedDisplayTotalsInHeader && (
          <TotalPriceColumn
            currency={serviceDataCache.currency.cache[currencyID]}
            settings={service.settings}
            services={serviceCalculations}
          />
        )}
        {isNeedShowButton && (
          <SelectionButton
            {...props}
            className={clsx('a-service-template-verticalTable-include-button', {
              'with-wrapper': settings.IsNeedShowWrapper,
            })}
          />
        )}
      </div>
      <div
        className={clsx('a-service-template-verticalTable-list', {
          'with-wrapper': settings.IsNeedShowWrapper,
        })}
      >
        <div className="a-service-template-verticalTable-list-wrapper">
          <div
            className={clsx(`a-service-template-verticalTable-scroll`, {
              [`columns-${columnsQty}`]: settings.IsCourseColumnHidden,
              [`columns-with-course-${columnsQty}`]: !settings.IsCourseColumnHidden,
            })}
          >
            <TableHeader langID={langID} settings={settings} columns={columnsToDisplay} />
            {isNeedDisplayTitleRow && (
              <TitleRow settings={settings} columns={columnsToDisplay} serviceProps={props} />
            )}
            {rows.map((rowID, i) => (
              <ServicesRow
                key={rowID}
                langID={langID}
                rowID={rowID}
                rowNumber={i + 1}
                isFirstRow={i === 0}
                totalRowsQty={rows.length}
                targetCurrency={targetCurrency}
                settings={settings}
                course={course}
                isOptionsLoading={props.isOptionsLoading}
                isOrderDisplay={props.isOrderDisplay}
                costColumnField={costField}
                costColumnFieldValue={store.rows[rowID]}
                onChangeCostColumnField={(value) => handleChangeCostFieldValue(rowID, value)}
                columns={columnsToDisplay}
                serviceCalculations={serviceCalculations}
                allOptions={options}
                fieldValues={fieldValues}
              />
            ))}
            {rows.length > 0 && (
              <ServiceSummaryRow
                course={course}
                settings={settings}
                columns={columns}
                totalCostColumn={totalCostColumn}
                targetCurrency={targetCurrency}
                serviceCalculations={serviceCalculations}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalTableTemplate;
