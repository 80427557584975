import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import { CountriesResponse } from '@services/requests/customsTaxService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProps } from '../../types';
import { CustomsPaymentsTemplateProduct } from '../types';

interface CustomsPaymentsDetailingMainProps extends ServiceProps {
  product: CustomsPaymentsTemplateProduct;
  countries: CountriesResponse;
  index: number;
}

function CustomsPaymentsDetailingMain({
  product,
  index,
  countries,
  globalFieldValues,
  globalFields,
  servicesFieldValues,
  servicesFields,
  service,
  options,
}: CustomsPaymentsDetailingMainProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const {
    country,
    code,
    codeDescription,
    weight,
    contractCurrencyCost,
    originCertificateExisting,
    selectedExcise,
    selectedExportFee,
    selectedFee,
    selectedAntidumpingFee,
    selectedVat,
  } = product;

  const fields = [...servicesFields, ...globalFields];
  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const customsModeField = fields.find((f) => f.code === settings.CustomsModeField);
  const contractCurrencyField = fields.find((f) => f.code === settings.ContractCurrencyField);
  const customsMode =
    options[customsModeField.code].find(
      (option) => option.value === fieldValues[customsModeField.code]
    )?.origin?.value ?? 1;

  const foundCountry = countries.find((c) => c.Code === country);
  const contractCurrency = options[contractCurrencyField.code].find(
    (currency) => currency.value === fieldValues[contractCurrencyField.code]
  );

  const formattedVatValue = selectedVat?.Value.split('|')[0] || '-';
  const formattedExciseValue = selectedExcise?.Value.split('|')[0] || '-';
  const formattedFeeValue = selectedFee?.Value.split('|')[0] || '-';
  const formattedExportFeeValue = selectedExportFee?.Value;
  const formattedAntidumpingFeeValue = selectedAntidumpingFee?.Value;

  const isExport = customsMode === 2;

  const certificateText = originCertificateExisting
    ? t('CustomsPayments:Yes')
    : t('CustomsPayments:No');
  const countryText = isExport
    ? t('CustomsPayments:DestinationCountryPlaceholder')
    : t('CustomsPayments:OriginCountryPlaceholder');

  return (
    <div className="product-wrapper">
      <div className="title">
        {t('CustomsPayments:ProductTitle')} #{index + 1}
      </div>
      <div className="product-main-wrap">
        <div className="product-main-col">
          <div className="product-main">
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{countryText}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{foundCountry?.Name}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:HSCodeLabel')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{code}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:ProductNameLabel')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{codeDescription}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">
                  {t('CustomsPayments:GrossWeightPlaceholder')}:
                </span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{weight}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:PricePlaceholder')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">
                  {getFormattedPrice(+contractCurrencyCost, 2)} {contractCurrency.label}
                </span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">
                  {t('CustomsPayments:CertificateOriginAvailable')}:
                </span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{certificateText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-main-col product-main-additional-tax">
          <div className="product-main">
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:VatGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{formattedVatValue}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:ExciseGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{formattedExciseValue}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:DutyGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">
                  {isExport ? formattedExportFeeValue : formattedFeeValue}
                </span>
              </div>
            </div>
            {!!formattedAntidumpingFeeValue && (
              <div className="product-main__row">
                <div className="product-main__col">
                  <span className="product-main__text">
                    {t('CustomsPayments:ForeignExchangeExpenses')}:
                  </span>
                </div>
                <div className="product-main__col">
                  <span className="product-main__text">{formattedAntidumpingFeeValue}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { CustomsPaymentsDetailingMain };
