import {
  AdditionServicesOptionsState,
} from '@pages/AdditionalServices/containers/additionServicesOptionsContext';
import { calculateFieldValues } from '@pages/AdditionalServices/containers/methods/calculateFieldValues';
import { clone } from '@pages/AdditionalServices/containers/methods/clone';
import { AdditionServicesState } from '@pages/AdditionalServices/containers/types';
import { BehaviorSubject } from 'rxjs';

/**
 * calculateFieldsSubscriber реализует подписчик на изменения данных опций
 * в стейте. При изменении опций значения пересчитываются.
 * Предназначено для расчета курсов валют для числовых полей при смене 
 *
 * @param serviceContext$
 */
export const calculateFieldsSubscriber =
  (serviceContext$: BehaviorSubject<AdditionServicesState>) =>
  (optionsStates: AdditionServicesOptionsState[]) => {
    if (optionsStates.length === 0) {
      return;
    }

    const currentState = serviceContext$.getValue();
    const state = clone(currentState);
    state.FieldValues = calculateFieldValues(
      state.FieldsToDisplay,
      state.FieldValues,
      optionsStates[optionsStates.length - 1].FieldOptions,
      undefined
    );
    serviceContext$.next(state);
  };
