export function getStyleFormattedNote(
  containerNode: HTMLDivElement,
  selector: string,
  classToAdd: string,
  classesToRemove: string[]
) {
  const elements = containerNode.querySelectorAll(selector);

  elements.forEach((element) => {
    element.classList.add(classToAdd);
    classesToRemove.forEach((cn) => element.classList.remove(cn));
  });

  return containerNode;
}
