export const getFormattedPrice = (price: number, minimumFractionDigits?: number) => {
  return new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 3,
    minimumFractionDigits,
  })
    .format(price)
    .replace(',', '.');
};

export const getReverseFormattedPrice = (price: string) => {
  return Number(price.replace(/\s+/g, ''));
};

export const moneyRounding = (price: number, decimal: number = 2) => {
  return +price.toFixed(decimal);
};
