import { Service, ServiceDirectory, ServiceField } from '@onlog-public/additional-services-types';
import { SelectOption } from '@pages/AdditionalServices/containers/services/optionsGenerator/types';
import {
  ExciseItem,
  ExportItem,
  ImportItem,
  VatItem,
} from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAntidumpingFeeCalc } from '../helpers/getAntidumpingFeeCalc';
import { getBaseCalc } from '../helpers/getBaseCalc';
import { getExciseCalc } from '../helpers/getExciseCalc';
import { getFeeCalc } from '../helpers/getFeeCalc';
import { getTotalCalc } from '../helpers/getTotalCalc';
import { getVatCalc } from '../helpers/getVatCalc';
import BaseTotalTable from '../Tables/BaseTotal/BaseTotalTable';
import BaseTotalTableHeader from '../Tables/BaseTotal/BaseTotalTableHeader';
import TotalTable from '../Tables/Total/TotalTable';
import TotalTableHeader from '../Tables/Total/TotalTableHeader';
import { CustomsPaymentsTemplateStore, ProductTotal } from '../types';

interface ResultTables {
  service: Service;
  variant: ServiceDirectory;
  productIndex: number;
  serviceTemplateStore: { [T in string]: any };
  totalContractCurrencyCost: number;
  totalProductsWeight: number;
  globalFields: ServiceField[];
  servicesFields: ServiceField[];
  globalFieldValues: { [T in string]: number };
  servicesFieldValues: { [T in string]: number };
  selectedFee: ImportItem;
  selectedAntidumpingFee: ImportItem | null;
  selectedVat: VatItem;
  selectedExcise: ExciseItem;
  selectedExportFee: ExportItem;
  currencies: CurrencyData[];
  isExport: boolean;
  options: { [T in string]?: SelectOption[] };

  onChangeServiceTemplateStore: (variant: string, service: string, store: any) => void;
}

function ResultTables({
  productIndex,
  service,
  variant,
  serviceTemplateStore,
  totalContractCurrencyCost,
  totalProductsWeight,
  globalFields,
  servicesFields,
  globalFieldValues,
  servicesFieldValues,
  selectedExcise,
  selectedFee,
  selectedAntidumpingFee,
  selectedVat,
  selectedExportFee,
  currencies,
  isExport,
  onChangeServiceTemplateStore,
}: ResultTables) {
  const { t } = useTranslation(['CustomsPayments']);

  const fields = [...servicesFields, ...globalFields];
  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];
  const product = store.products[productIndex];

  const contractCurrencyField = fields.find((f) => f.code === settings.ContractCurrencyField);
  const contractCurrency = currencies.find(
    (currency) => currency.id === fieldValues[contractCurrencyField.code].toString()
  );

  const borderDeliveryCurrencyField = fields.find(
    (f) => f.code === settings.BorderDeliveryCurrencyField
  );
  const otherExpensesCurrencyField = fields.find(
    (f) => f.code === settings.OtherExpensesCurrencyField
  );
  const borderDeliverySumField = fields.find((f) => f.code === settings.BorderDeliverySumField);
  const otherExpensesSumField = fields.find((f) => f.code === settings.OtherExpensesSumField);
  const borderDeliveryCurrency = currencies.find(
    (currency) => currency.id === fieldValues[borderDeliveryCurrencyField.code].toString()
  );
  const otherExpensesCurrency = currencies.find(
    (currency) => currency.id === fieldValues[otherExpensesCurrencyField.code].toString()
  );

  const changeProductTotalHandler = (key: keyof ProductTotal, value: number) => {
    const newStore = { ...store };

    const foundProductIndex = newStore.products.findIndex((p) => product.productId === p.productId);

    if (foundProductIndex === -1) {
      return;
    }

    const changedProduct = { ...newStore.products[foundProductIndex] };

    changedProduct.total = {
      ...changedProduct.total,
      [key]: value,
    };

    newStore.products.splice(productIndex, 1, changedProduct);

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });
  };

  const baseResult = getBaseCalc({
    productContractCurrencyCost: +product.contractCurrencyCost,
    contractCurrency,
    borderDeliveryCost: fieldValues[borderDeliverySumField.code],
    borderDeliveryCurrency,
    productWeight: +product.weight,
    otherExpensesCost: fieldValues[otherExpensesSumField.code],
    otherExpensesCurrency,
    productCost: +product.contractCurrencyCost,
    totalContractCurrencyCost: totalContractCurrencyCost,
    totalProductsWeight: totalProductsWeight,
    contractCurrencyLabel: t('CustomsPayments:ContractCurrencyTableTitle'),
    borderDeliveryLabel: t('CustomsPayments:BorderDeliveryLabel'),
    otherExpensesLabel: t('CustomsPayments:OtherExpenses'),
    baseTotalLabel: t('CustomsPayments:BaseTotalLabel'),
  });

  const baseTotalRubles = baseResult[baseResult.length - 1].rublesAmount;

  const [feeRowData, exportFeeRowData] = getFeeCalc({
    contractCurrency,
    currencies,
    product,
    selectedExportFee,
    selectedFee,
    title: t('CustomsPayments:DutyGroupTitle'),
    cost: baseTotalRubles,
  });

  useEffect(() => {
    changeProductTotalHandler(
      'feeSum',
      isExport ? +exportFeeRowData.rublesAmount : +feeRowData.rublesAmount
    );
  }, [exportFeeRowData.rublesAmount, feeRowData.rublesAmount]);

  const [antidumpingFeeRowData] = getAntidumpingFeeCalc({
    contractCurrency,
    currencies,
    product,
    selectedExportFee,
    selectedFee: selectedAntidumpingFee,
    title: t('CustomsPayments:AntidumpingDutyRowTitle'),
    cost: baseTotalRubles,
  });

  useEffect(() => {
    changeProductTotalHandler('antiDumpingSum', +antidumpingFeeRowData?.rublesAmount || 0);
  }, [antidumpingFeeRowData?.rublesAmount, selectedExportFee]);

  const exciseRowData = getExciseCalc({
    product,
    selectedExcise,
    title: t('CustomsPayments:ExciseGroupTitle'),
    cost: baseTotalRubles,
    currencies,
  });

  useEffect(() => {
    changeProductTotalHandler('exciseSum', +exciseRowData?.rublesAmount || 0);
  }, [exciseRowData?.rublesAmount]);

  const vatRowData = getVatCalc({
    exciseRowData,
    feeRowData,
    cost: baseTotalRubles,
    selectedVat,
    title: t('CustomsPayments:VatGroupTitle'),
  });

  useEffect(() => {
    changeProductTotalHandler('vatSum', +vatRowData.rublesAmount);
  }, [vatRowData.rublesAmount]);

  useEffect(() => {
    changeProductTotalHandler('customsSum', baseTotalRubles ?? 0);
  }, [baseTotalRubles]);

  const result = getTotalCalc({
    antidumpingFeeRowData,
    exciseRowData,
    exportFeeRowData,
    feeRowData,
    vatRowData,
    isExport,
    title: t('CustomsPayments:TotalCustomsPaymentsRowTitle'),
    productIndex,
    products: store.products,
  });

  return (
    <div className="result-wrap">
      <div className="result-item">
        <div className="result-item__table">
          <div className="a-service-template-customsPayments-base kendo-pdf--prevent-split">
            <div className="a-service-template-customsPayments-base-list">
              <div className="a-service-template-customsPayments-base-scroll">
                <BaseTotalTableHeader />
                <BaseTotalTable data={baseResult} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="result-item">
        <div className="result-item__table base-total">
          <div className="a-service-template-customsPayments kendo-pdf--prevent-split">
            <div className="a-service-template-customsPayments-list">
              <div className="a-service-template-customsPayments-scroll">
                <TotalTableHeader />
                <TotalTable data={result} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ResultTables };
