import { CountriesResponse } from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';

import { ServiceProps } from '../../types';
import { CustomsPaymentsProductItem } from '../CustomsPaymentsProductItem';
import { CustomsPaymentsTemplateProduct } from '../types';

interface CustomsPaymentsProductListProps extends ServiceProps {
  countries: CountriesResponse;
  products: CustomsPaymentsTemplateProduct[];
  isLastProduct: boolean;
  currencies: CurrencyData[];
}

function CustomsPaymentsProductList({
  products,
  isLastProduct,
  ...baseProps
}: CustomsPaymentsProductListProps) {
  const totalProductsWeight = products.reduce((prev, item) => prev + +item.weight, 0);
  const totalContractCurrencyCost = products.reduce(
    (prev, item) => prev + +item.contractCurrencyCost,
    0
  );

  return (
    <>
      {products.map((product, index) => (
        <CustomsPaymentsProductItem
          key={'customs-payments-product-' + product.productId}
          productIndex={index}
          isLastProduct={isLastProduct}
          totalProductsWeight={totalProductsWeight}
          totalContractCurrencyCost={totalContractCurrencyCost}
          {...baseProps}
        />
      ))}
    </>
  );
}

export { CustomsPaymentsProductList };
