import { ExportItem, ImportItem } from '@services/requests/customsTaxService/interface';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

import { CustomsPaymentsTemplateProduct } from '../types';
import { getCalculation } from './calculation';

interface GetFeeCalcProps {
  selectedFee: ImportItem;
  selectedExportFee: ExportItem;
  currencies: CurrencyData[];
  contractCurrency: CurrencyData;
  product: CustomsPaymentsTemplateProduct;
  title: string;
  cost: number;
}

function getFeeCalc({
  selectedFee,
  selectedExportFee,
  currencies,
  contractCurrency,
  product,
  title,
  cost,
}: GetFeeCalcProps) {
  const { productsCount } = product;

  let feeRowData: any = null;
  if (selectedFee?.Value === '0' || !selectedFee?.ValueDetail) {
    feeRowData = {
      id: 'fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
    };
  }

  const foundCurrency = currencies.find(
    (currency) => currency.code === selectedFee?.ValueDetail?.ValueCurrency
  );

  const count = selectedFee?.ValueDetail?.ValueCount ?? 0;
  const unit = selectedFee?.ValueDetail?.ValueUnit ?? '';
  const localCurrency = selectedFee?.ValueDetail?.ValueCurrency ?? '';
  const feeBase = +productsCount;

  let result = getCalculation(
    +count,
    unit,
    localCurrency,
    foundCurrency?.course ?? 1,
    cost,
    feeBase
  );

  if (selectedFee?.ValueDetailAdd) {
    const foundCurrencyAdd = currencies.find(
      (currency) => currency.code === selectedFee?.ValueDetailAdd?.ValueCurrency
    );
    const countAdd = selectedFee?.ValueDetailAdd?.ValueCount ?? 0;
    const unitAdd = selectedFee?.ValueDetailAdd?.ValueUnit ?? '';
    const localCurrencyAdd = selectedFee?.ValueDetailAdd?.ValueCurrency ?? '';

    result += getCalculation(
      +countAdd,
      unitAdd,
      localCurrencyAdd,
      foundCurrencyAdd?.course ?? 1,
      cost,
      feeBase
    );
  }

  if (selectedFee?.ValueDetail && selectedFee.ValueDetail.ValueCount2) {
    const foundCurrency2 = currencies.find(
      (currency) => currency.code === selectedFee?.ValueDetail?.ValueCurrency2
    );
    const count2 = selectedFee?.ValueDetail?.ValueCount2 ?? 0;
    const unit2 = selectedFee?.ValueDetail?.ValueUnit2 ?? '';
    const localCurrency2 = selectedFee?.ValueDetail?.ValueCurrency2 ?? '';

    const result2 = getCalculation(
      +count2,
      unit2,
      localCurrency2,
      foundCurrency2?.course ?? 1,
      cost,
      feeBase
    );

    let result3 = 0;

    if (selectedFee?.ValueDetail?.ValueCount3) {
      const foundCurrency3 = currencies.find(
        (currency) => currency.code === selectedFee?.ValueDetail?.ValueCurrency3
      );
      const count3 = selectedFee?.ValueDetail?.ValueCount3 ?? 0;
      const unit3 = selectedFee?.ValueDetail?.ValueUnit3 ?? '';
      const localCurrency3 = selectedFee?.ValueDetail?.ValueCurrency3 ?? '';

      result3 = getCalculation(
        +count3,
        unit3,
        localCurrency3,
        foundCurrency3?.course ?? 1,
        cost,
        feeBase
      );
    }

    if (result2 > result) {
      result = result2;
      if (result3 && result3 !== 0 && result > result3) {
        result = result3;
      }
    }
  }

  feeRowData = {
    id: 'fee',
    name: title,
    rate: selectedFee?.Value.split('|')[0],
    rublesAmount: result,
  };

  let exportFeeRowData: any = null;
  if (selectedExportFee?.Value === '0' || !selectedExportFee?.ValueDetail) {
    exportFeeRowData = {
      id: 'fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
    };
  }

  const foundExportCurrency =
    currencies.find(
      (currency) => currency.code === selectedExportFee?.ValueDetail?.ValueCurrency
    ) || contractCurrency;

  const countExport = selectedExportFee?.ValueDetail?.ValueCount ?? 0;
  const unitExport = selectedExportFee?.ValueDetail?.ValueUnit || '';
  const localCurrencyExport = selectedExportFee?.ValueDetail?.ValueCurrency ?? '';

  let resultExport = getCalculation(
    +countExport,
    unitExport,
    localCurrencyExport,
    foundExportCurrency?.course ?? 1,
    cost,
    feeBase
  );

  if (selectedExportFee?.ValueDetail && selectedExportFee.ValueDetail.ValueCount2) {
    const foundCurrency2 = currencies.find(
      (currency) => currency.code === selectedExportFee?.ValueDetail?.ValueCurrency2
    );
    const count2 = selectedExportFee?.ValueDetail?.ValueCount2 ?? 0;
    const unit2 = selectedExportFee?.ValueDetail?.ValueUnit2 ?? '';
    const localCurrency2 = selectedExportFee?.ValueDetail?.ValueCurrency2 ?? '';

    const resultExport2 = getCalculation(
      +count2,
      unit2,
      localCurrency2,
      foundCurrency2?.course ?? 1,
      cost,
      feeBase
    );

    if (resultExport2 > resultExport) {
      resultExport = resultExport2;
    }
  }

  exportFeeRowData = {
    id: 'fee',
    name: title,
    rate: selectedExportFee?.Value.split('|')[0],
    rublesAmount: resultExport,
  };

  return [feeRowData, exportFeeRowData];
}

export { getFeeCalc };
