export const CHECK_EXCLUDE_KEYS = ['notes', 'originCertificateExisting', 'total', 'invalidFields'];

export const VALIDATE_CHECKS = {
  isCalculated: true,
  code: /^\d{10}$/,
  codeDescription: /^.+$/,
  contractCurrencyCost: /^\d+$/,
  country: /^\d+$/,
  weight: /^(\d+\.\d+)|([1-9]\d*)$/,
  productsCount: /^[1-9]\d*$/,
  maximumRetailPackagePrice: /^[1-9]\d*$/,
  packagesCount: /^[1-9]\d*$/,
  exciseCount: /^[1-9]\d*$/,
};
