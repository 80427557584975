import calculateFilterPriceData from "@pages/AdditionalServices/containers/methods/calculateFilterPriceData";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {loadServiceDataCache} from "@pages/AdditionalServices/containers/methods/loadServiceDataCache";
import serviceToCalculateGenerator from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {servicesServicesService} from "@services/requests/servicesService/servicesServicesService";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {BehaviorSubject} from "rxjs";
import {v4} from "uuid";

/**
 * calculateServicesSubscriber содержит реализацию подписчика на
 * расчет стоимости услуг. Подключается к шине расчета.
 * Подписчик генерирует уникальный ID запроса и сохраняет результат
 * расчета только тогда, когда запрос расчета был последним.
 *
 * @param serviceContext$
 */
export const calculateServicesSubscriber = (serviceContext$: BehaviorSubject<AdditionServicesState>) => async (
  buffer: { isFieldChanges: boolean, isNeedChooseVariant: boolean }[]
) => {
  if (buffer.length === 0) {
    return
  }

  const uuid = v4()
  const state = serviceContext$.getValue()
  if (!state.IsNeedCalculateServices) {
    return
  }

  serviceContext$.next({
    ...clone(state),
    IsServicesCalculating: true,
    LastServiceCalculationID: uuid,
  })

  // Собираем все услуги, которые необходимо рассчитать для каждого варианта
  const servicesToCalculate = state.Variants.map(v => ({
    variant: v,
    services: state.VariantServices[v.id]
      .map(s => [s, ...s.additionServices]).flat(1)
      .map(s => serviceToCalculateGenerator().Generate(
        s,
        state.CurrencyID,
        state.FieldValues,
        state.VariantFieldValues[v.id],
        state.ServiceTemplateStore[v.id][s.id],
      )).flat(1),
  }))

  const result = await Promise.all(servicesToCalculate.map(async data => {
    return {
      variant: data.variant,
      result: await servicesServicesService().ServicePriceCalculation(data.services),
    }
  }))

  const calculationResult: { [T in string]: ServicesServicePriceCalculationMutationProps[] } = {}
  result.map(v => {
    calculationResult[v.variant.id] = v.result
  })

  await loadServiceDataCache(state.CurrencyID, state.Variants, state.VariantServices, calculationResult)

  const newState = serviceContext$.getValue()
  if (newState.LastServiceCalculationID !== uuid) {
    return
  }

  const isNeedRecalculatePropsFilterState = !!buffer.find(v => v.isFieldChanges)
  const isNeedChooseVariant = !!buffer.find(v => v.isNeedChooseVariant)

  let selectedVariant = newState.SelectedVariant
  if (isNeedChooseVariant) {
    const variants = Object.keys(calculationResult)
      .map(variant => {
        return {
          variant,
          sum: calculationResult[variant].reduce((v, s) => {
            if (s.result.result.fullPriceInTargetCurrency <= 0) {
              return v
            }

            return v + s.result.result.fullPriceInTargetCurrency
          }, 0),
        }
      })
      .sort((a, b) => a.sum > b.sum ? 1 : -1)

    selectedVariant = variants[0].variant
  }

  calculateFilterPriceData(
    calculationResult,
    !!isNeedRecalculatePropsFilterState,
    newState.SelectedVariant,
  )

  serviceContext$.next({
    ...clone(newState),
    IsServicesCalculating: false,
    LastServiceCalculationID: "",
    SelectedVariant: selectedVariant,
    VariantsCalculationResult: calculationResult,
  })
}