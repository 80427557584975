import { Service } from '@onlog-public/additional-services-types';
import { ServiceFieldSettings } from '@onlog-public/additional-services-types/src/ServiceFieldSettings';
import AdditionServiceFields from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields';
import getCostConfiguration from '@pages/AdditionalServices/components/Service/getCostConfiguration';
import getDuplicateFieldsConfiguration from '@pages/AdditionalServices/components/Service/getDuplicateFieldsConfiguration';
import { ServiceProps } from '@pages/AdditionalServices/components/Service/types';
import React, { FC } from 'react';

export interface ColumnServiceFieldsProps {
  columns: Service[];
  serviceProps: ServiceProps;
}

const ColumnServiceFields: FC<ColumnServiceFieldsProps> = (props) => {
  const {
    columns,
    serviceProps: {
      langID,
      globalFields,
      servicesFields,
      globalFieldValues,
      servicesFieldValues,
      isOptionsLoading,
      options,
      isOrderDisplay,
      onChangeServiceFieldValue,
      onChangeGlobalFieldValue,
    },
  } = props;

  let serviceFieldCodes = columns
    .map((c) => c.fields)
    .flat(1)
    .map((f) => f.code);
  const additionFieldsCodes: string[] = [];
  let additionFieldsSettings: { [T in string]: ServiceFieldSettings } = {};

  columns.map((column) => {
    const templateSettings = getDuplicateFieldsConfiguration(column);
    const costSettings = getCostConfiguration(column);

    if (!!templateSettings) {
      additionFieldsCodes.push(...Object.keys(templateSettings.Fields));
      additionFieldsSettings = { ...additionFieldsSettings, ...templateSettings.Fields };
    }

    // Если колонки услуги выводятся в колонке стоимость, то их необходимо
    // исключить из других колонок.
    if (!!costSettings) {
      const costCodes = [costSettings.CurrencyFieldCode, costSettings.PriceFieldCode];
      serviceFieldCodes = serviceFieldCodes.filter((c) => !costCodes.includes(c));
    }
  });

  const fieldsToDisplay = [
    ...servicesFields.filter((f) => serviceFieldCodes.includes(f.code)),
    ...servicesFields.filter((f) => additionFieldsCodes.includes(f.code)),
    ...globalFields.filter((f) => additionFieldsCodes.includes(f.code)),
  ];

  return (
    <AdditionServiceFields
      allFields={fieldsToDisplay}
      fields={fieldsToDisplay}
      fieldValues={{ ...globalFieldValues, ...servicesFieldValues }}
      langID={langID}
      onChange={(field, value) => {
        if (serviceFieldCodes.includes(field)) {
          return onChangeServiceFieldValue(field, value);
        }

        onChangeGlobalFieldValue(field, value);
      }}
      isOptionsLoading={isOptionsLoading}
      options={options}
      templateSettings={additionFieldsSettings}
      isFieldsDisabled={isOrderDisplay}
    />
  );
};

export default ColumnServiceFields;
