import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import {customShadows} from '@assets/theme/shadows';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';

const OrderListItemStyledContainer = styled(Paper)`
    position: relative;
    padding: 25px 20px 25px 30px;
    border-radius: 18px;
    box-shadow: ${customShadows.light.z20};
    display: grid;
    grid-template-columns: 40px 1fr min-content 20px;
    gap: 20px 17px;
    align-items: flex-start;
    min-height: 107px;
    width: 100%;

    &.mobile {
        padding: 16px;
        gap: 12px 0;
    }

    input[type='checkbox'] {
        display: block;
    }

    .service-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
    }

    .route-select {
        align-self: center;
    }

	.customs-payments {
		gap: 10px;
		display: flex;
		flex-direction: column;
	}

    @media (max-width: ${breakpoints.values.sm}px) {
        width: 100%;
        grid-auto-flow: row;
        grid-template-columns: 1fr;

        .mobile-actions {
            display: grid;
            grid-template-columns: 1fr max-content max-content;
            align-items: center;
            gap: 30px;
            justify-content: end;

            .close {
                width: 24px;
                height: 24px;
            }
        }

        .route-select {
            justify-content: flex-start;
            margin-left: -4px;
        }
    }

    .right-column {
        display: grid;
        gap: 10px;
        text-align: right;

        @media (max-width: ${breakpoints.values.xsm}px) {
            order: -1;
            display: flex;
            gap: 17px;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .price {
        white-space: nowrap;
        margin-right: 3px;
        font-weight: 600;
        font-size: 16px;
        color: black;
        text-align: right;
        padding-right: 5px;
        padding-bottom: 4px;

        @media (max-width: ${breakpoints.values.xsm}px) {
            text-align: right;
            width: 100%;
            padding-left: 0;
        }
    }

    .variant-price-wrap {
        display: flex;
        align-items: center;
    }

    .order-product-component {
        display: grid;
        gap: 12px;
        margin-bottom: 16px;
        color: ${palette.light.common.black};
        
        .cargo {
            font-size: 13px;
            font-weight: 500;
            
            &--item {
                padding-left: 12px;
            }

            &--title {
                padding-left: 0;
                padding-bottom: 4px;
                font-size: 12px;
                font-weight: 300;
                color: #707070;
            }
        }
        
        .route-marks {
            display: grid;
            grid-auto-columns: min-content;
            grid-auto-flow: column;
            grid-gap: 12px;
            
            .route-is-not-full {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 6px;
                align-items: center;
                line-height: 0;
                border: 2px solid #ffa630;
                padding: 6px 10px;
                border-radius: 8px;
                cursor: default;
                white-space: nowrap;

                > svg, > div {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 0;
                    color: #ffa630;
                }
            }
        }
    }

    .order-item {
        &__service-content {
            grid-column: span 2;
        }

        &__service-actions {
            position: absolute;
            right: 55px;
            top: 23px;

            .icon-button-block {
                margin-top: 0;
            }

            &.--top {
                bottom: auto;
                top: 23px;
            }
        }
    }

    .calc-dates {
        color: ${palette.light.grey[300]};
        font-size: 12px;
        line-height: 100%;
        font-weight: 300;

        &.is-mobile {
            text-align: right;
        }
    }

    .close {
        width: 20px;
        height: 20px;
        background-color: ${palette.light.grey[100]};
        color: ${palette.light.grey[400]};
        padding: 0;

        &.service-close {
            margin-top: ${({theme}) => theme.spacing(1.25)};
        }

        @media (hover: hover) {
            &:hover {
                color: ${palette.light.grey[500]};
            }
        }

        svg {
            font-size: 16px;
            color: inherit;
            border-radius: 50%;
        }
    }

    .icon-button {
        padding: 0;
        width: ${({theme}) => theme.spacing(4)};
        height: ${({theme}) => theme.spacing(4)};

        &-block {
            display: flex;
            justify-content: flex-end;

            @media (max-width: ${breakpoints.values.xsm}px) {
                gap: 8px;
                margin-top: 0;
            }
        }

        svg {
            font-size: 24px;

            @media (max-width: ${breakpoints.values.xsm}px) {
                font-size: 26px;
            }
        }
    }
`;

export default OrderListItemStyledContainer;
